import { PropsWithChildren } from "react";

import { Socket, io } from "socket.io-client";

import XTerm from "@/components/XTerm";
import { TerminalConnection } from "@/providers/useTerminals";

interface ClientEvents {
  "terminal:ready": () => void;
  "terminal:output": (data: string) => void;
  "terminal:exit": () => void;
}

interface ServerEvents {
  "terminal:leave": () => void;
  "terminal:input": (data: string) => void;
  "terminal:resize": (cols: number, rows: number) => void;
}

export type TerminalSocket = Socket<ClientEvents, ServerEvents>;

interface CloudResourceCredentials extends PropsWithChildren {
  credentials: string;
  token: string;
}

export interface CloudResourceConfig {
  id: string;
  credentials?: CloudResourceCredentials;
  cluster: string;
  container: string;
  region: string;
  session_name: string;
}

const serverAddress = process.env.NEXT_PUBLIC_CONSOLE_BASE_URL || "http://localhost:8080";

export class TerminalManager {
  mainSocket: Socket;
  terminalSockets: Record<string, TerminalSocket>;

  constructor() {
    this.mainSocket = io(serverAddress, { path: "/console", autoConnect: false });
    this.terminalSockets = {};
  }

  joinTerminal(
    terminal: TerminalConnection,
    xterm: XTerm,
    options: {
      onJoin?: (socket: TerminalSocket) => void;
      onDetach?: () => void;
      onExit?: () => void;
    }
  ) {
    const { terminalId, instanceUid, locator } = terminal;
    const socket = this.mainSocket.io.socket(`/${terminalId}`, {
      auth: {
        token: instanceUid,
        locator,
      },
    });
    socket.on("terminal:ready", () => {
      if (!this.isConnected(terminalId)) {
        socket.offAny();
        xterm.terminal.clear();
        socket.on("terminal:output", data => {
          xterm.terminal.write(data);
        });
        socket.on("terminal:detach", () => {
          xterm.terminal.writeln("\r\nConnection lost.");
          options.onDetach?.();
        });
        socket.on("terminal:exit", () => {
          options.onExit?.();
        });
        this.terminalSockets[terminalId] = socket;
      }
      options.onJoin?.(socket);
    });
    socket.connect();
  }

  disconnectTerminal(terminalId: string) {
    this.terminalSockets[terminalId]?.disconnect();
    delete this.terminalSockets[terminalId];
  }

  isConnected(terminalId: string) {
    return Object.keys(this.terminalSockets).includes(terminalId);
  }

  dispose() {
    Object.keys(this.terminalSockets).forEach((key: string) => {
      this.terminalSockets[key].disconnect();
    });
    this.mainSocket.disconnect();
  }
}
