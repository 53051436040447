import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    body: {
      textAlign: "center",
      "> *": {
        marginBottom: 4,
      },
    },
    dialogContainer: {
      boxShadow: "3xl",
    },
    header: {
      color: "text.darkest",
    },
  },
});
