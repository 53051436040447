import { useCallback, useEffect } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { FragmentType, getFragmentData, graphql } from "@/__generated__";
import { Maybe } from "@/__generated__/graphql";

const Datadog_User = graphql(`
  fragment Datadog_User on User {
    isEmployee
  }
`);

function Datadog(props: { currentUser?: Maybe<FragmentType<typeof Datadog_User>> }) {
  const currentUser = getFragmentData(Datadog_User, props.currentUser);
  const beforeSend = useCallback(() => {
    if (currentUser?.isEmployee) {
      // Don't send data for Release account users
      return false;
    }
  }, [currentUser]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE === "prod" || process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED) {
      datadogRum.init({
        beforeSend: beforeSend,
        applicationId: "d100a9a5-03a0-4aa1-8d9a-4f130353f37e",
        clientToken: "pubc407a1870fe1bd6ffffd95716ee06d29",
        site: "datadoghq.com",
        service: "spacedust-app",
        version: process.env.NEXT_PUBLIC_RELEASE_COMMIT_SHORT,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
      });

      datadogRum.startSessionReplayRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default Datadog;
