/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { FragmentType, getFragmentData, graphql } from "@/__generated__";
import { Maybe } from "@/__generated__/graphql";

const Freshpaint_User = graphql(`
  fragment Freshpaint_User on User {
    id
    isEmployee
    username
    primaryEmailAddress {
      email
    }
  }
`);

const Freshpaint_WorkspaceCxn = graphql(`
  fragment Freshpaint_WorkspaceCxn on WorkspaceConnection {
    nodes {
      id
      name
      isOnboarded
      unhashedDatabaseId
    }
  }
`);

function Freshpaint({
  isImpersonating,
  ...props
}: {
  currentUser?: Maybe<FragmentType<typeof Freshpaint_User>>;
  workspaces?: Maybe<FragmentType<typeof Freshpaint_WorkspaceCxn>>;
  isImpersonating?: boolean;
}) {
  const currentUser = getFragmentData(Freshpaint_User, props.currentUser);
  const workspaces = getFragmentData(Freshpaint_WorkspaceCxn, props.workspaces);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE === "prod" &&
      process.env.NEXT_PUBLIC_FRESHPAINT_ID &&
      !initialized
    ) {
      require("./scripts/freshpaint");

      window.freshpaint?.init(process.env.NEXT_PUBLIC_FRESHPAINT_ID);
      window.freshpaint?.page();
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (workspaces && currentUser && !isImpersonating && initialized) {
      const accountsIds = workspaces.nodes.map(({ unhashedDatabaseId }) => unhashedDatabaseId);
      const isOnboarded = workspaces.nodes.some(workspace => workspace.isOnboarded);
      const {
        isEmployee,
        username,
        primaryEmailAddress: { email },
      } = currentUser;

      window.freshpaint.identify(currentUser.primaryEmailAddress.email, {
        accountsIds,
        email,
        isEmployee,
        isOnboarded,
        username,
      });
    }
  }, [workspaces, currentUser, isImpersonating, initialized]);

  return null;
}

export default Freshpaint;
