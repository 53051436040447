import { ServerError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { startsWith } from "lodash";
import { toast } from "sonner";

import { pathTo } from "@/utils/pathGenerators";

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, response, forward, operation }) => {
  // Redirect to login flow if user is not authenticated

  const allowList = ["/login", "/logout", "/reset-password", "/invites", "/register", "/vercel-integration"];
  if (networkError) {
    const statusCode = (networkError as ServerError).statusCode;

    if (
      typeof window !== "undefined" &&
      !allowList.some(path => startsWith(window.location.pathname, path))
    ) {
      if (statusCode === 401) {
        window.location.replace(pathTo("/login", { search: { next: window.location.href } }));
        return;
      } else if (statusCode === 403) {
        window.location.replace("/login/disabled");
        return;
      }
    }
    return;
  }

  const unconfirmedAllowList = [...allowList, "/register/verify"];
  if (graphQLErrors) {
    const unverifiedError = graphQLErrors.find(error => error.extensions?.code === "UNVERIFIED_USER");
    if (
      typeof window !== "undefined" &&
      !unconfirmedAllowList.includes(window.location.pathname) &&
      unverifiedError
    ) {
      window.location.replace(
        pathTo("/register/verify", {
          keepQuery: true,
          search: {
            email: unverifiedError.extensions.email as string,
          },
        })
      );
      return;
    }
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      toast.error("An unexpected error occurred", {
        id: message,
        description: message,
      });
    });
  }

  forward(operation);
});

export default errorLink;
