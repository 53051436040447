import { ApolloLink } from "@apollo/client";
import { toast } from "sonner";

const networkErrorResolvedLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // Close retryToasts after successful response
    toast.dismiss();
    return response;
  });
});
export default networkErrorResolvedLink;
