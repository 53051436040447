import { InMemoryCache, makeVar } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import possibleTypesData from "@/__generated__/fragment-matches";
import {
  EnvironmentPipelineStageStateEnum,
  EnvironmentPipelineStateEnum,
  TaskStateEnum,
} from "@/__generated__/graphql";

export const baseTimeVar = makeVar<dayjs.Dayjs | null>(null);

dayjs.extend(duration);
const relativeDuration = (startedAt?: string) =>
  dayjs.duration((baseTimeVar() ?? dayjs()).diff(dayjs(startedAt))).asSeconds();

export const cache: InMemoryCache = new InMemoryCache({
  possibleTypes: possibleTypesData.possibleTypes,
  dataIdFromObject(object) {
    return object.id ? `${object.id}` : undefined;
  },
  typePolicies: {
    App: {
      fields: {
        buildArguments: relayStylePagination(),
        builds: relayStylePagination(),
        dependencySchedules: relayStylePagination(),
        environments: relayStylePagination(["filters"]),
        environmentPipelines: relayStylePagination(),
        services: relayStylePagination(),
        templates: relayStylePagination(),
      },
    },
    Build: {
      fields: {
        logs: relayStylePagination(),
      },
    },
    CloudIntegration: {
      fields: {
        databasePools: relayStylePagination(),
        clusters: relayStylePagination(),
        domains: relayStylePagination(),
      },
    },
    Cluster: {
      fields: {
        nodeGroups: relayStylePagination(),
      },
    },
    DatabasePool: {
      fields: {
        items: relayStylePagination(),
      },
    },
    Environment: {
      fields: {
        builds: relayStylePagination(),
        configurations: relayStylePagination(),
        pipelines: relayStylePagination(),
        namespaces: relayStylePagination(),
        dependencySchedules: relayStylePagination(),
      },
    },
    EnvironmentPipeline: {
      fields: {
        elapsedTime: {
          read(_, { readField }) {
            const state = readField("state");
            return state !== EnvironmentPipelineStateEnum.Running
              ? readField("totalDuration")
              : relativeDuration(readField("startedAt"));
          },
        },
      },
    },
    EnvironmentPipelineStage: {
      fields: {
        elapsedTime: {
          read(_, { readField }) {
            const state = readField("state");
            return state !== EnvironmentPipelineStageStateEnum.Running
              ? readField("totalDuration")
              : relativeDuration(readField("startedAt"));
          },
        },
      },
    },
    EnvironmentPresetPool: {
      fields: {
        items: relayStylePagination(),
      },
    },
    User: {
      fields: {
        emailAddresses: relayStylePagination(),
        identities: relayStylePagination(),
        sentInvites: relayStylePagination(),
        pendingInvites: relayStylePagination(),
        roles: relayStylePagination(),
      },
    },
    Workspace: {
      fields: {
        apps: relayStylePagination(),
        buildArgs: relayStylePagination(),
        cloudIntegrations: relayStylePagination(),
        clusters: relayStylePagination(),
        databasePools: relayStylePagination(),
        deliveryInstallations: relayStylePagination(),
        domains: relayStylePagination(),
        environmentPresetPools: relayStylePagination(),
        externalRegistries: relayStylePagination(),
        identityProviders: relayStylePagination(),
        invites: relayStylePagination(),
        secrets: relayStylePagination(),
        sshKeys: relayStylePagination(),
        users: relayStylePagination(),
      },
    },
    Task: {
      fields: {
        elapsedTime: {
          read(_, { readField }) {
            const state = readField("state");
            return state !== TaskStateEnum.Running
              ? readField("totalDuration")
              : relativeDuration(readField("startedAt"));
          },
        },
        logs: relayStylePagination(),
      },
    },
  },
});
