import { defineStyleConfig } from "@chakra-ui/react";

export const sizes = {
  body1: {
    lineHeight: "24px",
    fontSize: "fs.300",
  },
  body2: {
    lineHeight: "22px",
    fontSize: "fs.200",
  },
  body3: {
    lineHeight: "20px",
    fontSize: "fs.100",
  },
  body4: {
    lineHeight: "18px",
    fontSize: "fs.75",
  },
  body5: {
    lineHeight: "16px",
    fontSize: "fs.50",
  },
};

export default defineStyleConfig({
  baseStyle: {
    fontSize: "fs.300",
  },
  variants: {
    code: {
      color: "plum.500",
      backgroundColor: "gray.50",
      borderRadius: "4px",
      padding: "4px 12px",
    },
    verticalLabel: {
      writingMode: "vertical-lr",
      fontWeight: "medium",
      letterSpacing: "ls.200",
    },
    subtleUppercase: {
      color: "text.lightest",
      fontSize: "fs.50",
      fontWeight: "medium",
      textTransform: "uppercase",
      letterSpacing: "ls.250",
    },
    help: {
      color: "text.lighter",
      fontSize: "sm",
    },
  },
  sizes,
  defaultProps: {
    size: "body2",
  },
});
