import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const spaced = defineStyle({
  item: {
    borderWidth: 1,
    borderColor: "border.base",
    p: 4,
    "&:first-child": {
      borderTopRadius: "md",
    },
    "& + &": {
      borderTopWidth: 0,
    },
    "&:last-child": {
      borderBottomRadius: "md",
    },
  },
});

const dataList = defineStyle({
  item: {
    borderWidth: 1,
    borderColor: "border.base",
    "li:first-of-type": {
      borderTopRadius: "md",
    },
    "& + &": {
      borderTopWidth: 0,
    },
    "li:last-child": {
      borderBottomRadius: "md",
    },
  },
});

const navMenu = defineStyle({
  item: {
    "&[href]": {
      _hover: {
        bg: "blackAlpha.200",
        '[data-active="true"]': {
          bg: "white",
        },
      },
    },
    "&.collapsedHover": {
      _hover: {
        bg: "background.darkest",
        filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))",
      },
      _active: {
        _hover: {
          bg: "background.darkest",
          filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))",
        },
      },
    },
    transitionDuration: "normal",
    letterSpacing: "ls.200",
    color: "text.darker",
    borderRadius: "md",
    p: 4,
    w: "full",
    my: 1.5,
    display: "flex",
    fontWeight: "medium",
    '&[aria-current="step"], &[aria-current="page"]': {
      bg: "background.base",
      color: "text.primary",
    },
    '&[data-checked="true"] > svg': {
      color: "text.secondary",
    },

    _active: {
      _hover: {
        transform: "scale(.98)",
        bg: "blackAlpha.300",
        textDecoration: "none",
      },
    },
  },
  icon: {
    w: "1.25rem",
    h: "1.25rem",
  },
});

export default defineStyleConfig({
  variants: { spaced, navMenu, dataList },
});
