import borders from "./borders";
import colors from "./colors";
import sizes from "./sizes";
import spacing from "./spacing";
import typography from "./typography";

export const foundations = {
  colors,
  ...typography,
  sizes,
  space: spacing,
  borders,
};
