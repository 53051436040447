import { breadcrumbAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);
const baseStyle = definePartsStyle(() => ({
  item: {
    color: "text.base",
  },
  link: {
    color: "text.base",
    fontSize: "fs.200",
  },
}));

export default defineMultiStyleConfig({ baseStyle });
