import { SystemStyleObject, extendTheme, withDefaultColorScheme, type ThemeConfig } from "@chakra-ui/react";
import { GlobalStyleProps } from "@chakra-ui/theme-tools";

import layerStyles from "@/theme/layerStyles";
import textStyles from "@/theme/textStyles";

import codemirror from "./codemirror";
import { components } from "./components";
import { foundations } from "./foundations";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
const theme = extendTheme(
  {
    codemirror,
    config,
    ...foundations,
    shadows: {
      outline: "0 0 0 3px rgb(154 47 143 / 38%)",
    },
    semanticTokens: {
      colors: {
        success: "success.500",
        error: "error.500",
        text: {
          base: { _light: "gray.600", _dark: "whiteAlpha.700" },
          primary: { _light: "plum.500", _dark: "plum.200" },
          secondary: { _light: "niagara.500", _dark: "niagara.400" },
          tertiary: { _light: "violet.500", _dark: "violet.300" },
          darker: { _light: "gray.700", _dark: "whiteAlpha.800" },
          darkest: { _light: "gray.800", _dark: "whiteAlpha.900" },
          lighter: { _light: "gray.500", _dark: "whiteAlpha.600" },
          lightest: { _light: "gray.400", _dark: "whiteAlpha.500" },
        },
        background: {
          base: { default: "white", _dark: "gray.700" },
          darker: { default: "gray.50", _dark: "gray.800" },
          darkest: { default: "gray.100", _dark: "gray.900" },
          popper: { default: "white", _dark: "gray.900" }, // @todo
          hover: "border.darker",
        },
        border: {
          base: { _light: "gray.150", _dark: "blackAlpha.400" },
          darker: { _light: "gray.200", _dark: "blackAlpha.400" },
          lighter: { _light: "gray.100", _dark: "blackAlpha.500" },
          lightest: { _light: "gray.50", _dark: "blackAlpha.400" },
          primary: { _light: "plum.500", _dark: "plum.200" },
        },
      },
    },
    styles: {
      global: (props: GlobalStyleProps): SystemStyleObject => ({
        body: {
          fontSize: "fs.100",
          fontWeight: "medium",
          letterSpacing: "ls.100",
          borderColor: "border.base",
          color: "text.base",
          bg: "background.base",
          lineHeight: "base",
          fontFeatureSettings: '"ss02" on, "ss03" on, "ss04" on',
        },
        "*::placeholder": {
          color: "text.lightest",
        },
        "*, *::before, &::after": {
          borderColor: "border.base",
        },
      }),
    },
    components: { ...components },
    layerStyles,
    textStyles,
  },
  withDefaultColorScheme({ colorScheme: "plum", components: ["Button", "Radio", "Switch"] }),
  withDefaultColorScheme({ colorScheme: "clear", components: ["Code"] })
);

// These are all the colors from default theme that get merged into our theme.
// Here we're stripping out the ones we don't want so we don't use them
//  and so that Stories have less noise.
[
  // "transparent",
  // "current",
  // "black",
  // "white",
  // "whiteAlpha",
  // "blackAlpha",
  // "gray",
  // "red",
  //"yellow",
  //"orange",
  "green",
  "teal",
  //"blue",
  "cyan",
  "purple",
  "pink",
  "linkedin",
  "facebook",
  "messenger",
  "whatsapp",
  "twitter",
  "telegram",
].map(color => {
  delete theme.colors[color];
});

export default theme;

export const storyTheme = { colors: theme.colors, components: theme.components };
