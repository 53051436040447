import { defineStyleConfig } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";

const $bg = cssVar("tooltip-bg");
const $arrowBg = cssVar("popper-arrow-bg");

const tooltip = defineStyleConfig({
  baseStyle: () => {
    const bg = "background.base";

    return {
      [$bg.variable]: `colors.${bg}`,
      px: 4,
      py: 3,
      bg: [$bg.reference],
      [$arrowBg.variable]: [$bg.reference],
      color: "text.darker",
      borderRadius: "md",
      fontWeight: "medium",
      letterSpacing: "ls.200",
      lineHeight: "20px",
      fontSize: "fs.100",
      boxShadow: "xl",
      maxW: "290px",
      zIndex: "tooltip",
    };
  },
});

export default tooltip;
