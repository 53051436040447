import { Consumer, createContext, PropsWithChildren, useContext } from "react";

export default function createProvider<T, K>(
  initialData: T,
  useHook: (arg0: K) => T,
  displayName?: string
): [() => T, React.FC<PropsWithChildren<K>>, Consumer<T>] {
  const Context = createContext<T>(initialData);
  Context.displayName = displayName;

  const useProvider = () => useContext(Context);

  function Provider(props: PropsWithChildren<K>) {
    const { children } = props;
    const providerValue = useHook(props);
    return <Context.Provider value={providerValue}>{children}</Context.Provider>;
  }

  return [useProvider, Provider, Context.Consumer];
}
