/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-filename-extension */

import { RefObject, useCallback, useRef, useState } from "react";

import { ImperativePanelHandle } from "react-resizable-panels";
import { useBoolean, useEffectOnce, useLocalStorage } from "usehooks-ts";

import localStorageKeys from "@/enums/localStorageKeys";
import createProvider from "@/providerFactories/createProvider";
import { TerminalManager } from "@/utils/TerminalManager";
import uuid from "@/utils/uuid";

type TerminalLocation = "panel" | "popout";

export interface TerminalRequest {
  location: TerminalLocation;
  locator: string;
  instanceUid: string;
  namespace: string;
  instance: string;
  container: string;
  app: string;
  environment: string;
  closing?: boolean;
}

export interface TerminalConnection extends TerminalRequest {
  terminalId: string; // uniquely identifiable
}

type TerminalsProviderType = {
  terminals: TerminalConnection[];
  terminalManager?: TerminalManager;
  terminalPanelRef: RefObject<ImperativePanelHandle>;
  terminalPanelCollapsed: boolean;
  terminalTabIndex: number;
  setTerminalTabIndex: (i: number) => void;
  panelTerminals: TerminalConnection[];
  popoutTerminals: TerminalConnection[];
  addTerminal: (terminal: TerminalRequest) => void;
  closeTerminal: (terminalId: string) => void;
  removeTerminal: (terminalId: string) => void;
  popTerminalOut: (terminalId: string) => void;
  popTerminalIn: (terminalId: string) => void;
  expandTerminalPanel: () => void;
  collapseTerminalPanel: () => void;
};

const initialValues: TerminalsProviderType = {
  terminals: [],
  terminalPanelRef: { current: null },
  terminalTabIndex: 0,
  terminalPanelCollapsed: false,
  setTerminalTabIndex: () => null,
  terminalManager: new TerminalManager(),
  panelTerminals: [],
  popoutTerminals: [],
  addTerminal: () => null,
  closeTerminal: () => null,
  removeTerminal: () => null,
  popTerminalOut: () => null,
  popTerminalIn: () => null,
  expandTerminalPanel: () => null,
  collapseTerminalPanel: () => null,
};

const useTerminalsProviderHook = () => {
  const [terminals, setTerminals] = useLocalStorage<TerminalConnection[]>(
    localStorageKeys.TERMINALS_PANEL,
    []
  );
  const [terminalManager, setTerminalManager] = useState<TerminalManager>();
  const [terminalTabIndex, setTerminalTabIndex] = useState<number>(0);
  const terminalPanelRef = useRef<ImperativePanelHandle>(null);
  const { value: terminalPanelCollapsed, setValue: setTerminalPanelCollapsed } = useBoolean(false);

  const addTerminal = useCallback(
    (terminal: TerminalRequest) => {
      const terminalConnection = { ...terminal, terminalId: uuid() };
      setTerminals(terminals => [...terminals, terminalConnection]);
      if (terminal.location === "panel") {
        setTerminalTabIndex(terminals.length);
      }
    },
    [setTerminals, terminals]
  );

  const popTerminalOut = useCallback(
    (terminalId: string) => {
      // const popoutWindow = window.open(pathTo(`/terminal/${terminal.locator}`));
      setTerminals(terminals =>
        terminals.map(t => (t.terminalId === terminalId ? { ...t, location: "popout" } : t))
      );
    },
    [setTerminals]
  );

  const popTerminalIn = useCallback(
    (terminalId: string) => {
      setTerminals(terminals =>
        terminals.map(t => (t.terminalId === terminalId ? { ...t, location: "panel" } : t))
      );
    },
    [setTerminals]
  );

  const closeTerminal = useCallback(
    (terminalId: string) => {
      setTerminals(terminals =>
        terminals.map(t => (t.terminalId === terminalId ? { ...t, closing: true } : t))
      );
    },
    [setTerminals]
  );

  const removeTerminal = useCallback(
    (terminalId: string) => {
      setTerminals(terminals => terminals.filter(t => t.terminalId !== terminalId));
    },
    [setTerminals]
  );

  const expandTerminalPanel = useCallback(() => {
    setTerminalPanelCollapsed(false);
    terminalPanelRef.current?.expand();
  }, [setTerminalPanelCollapsed]);

  const collapseTerminalPanel = useCallback(() => {
    setTerminalPanelCollapsed(true);
    terminalPanelRef.current?.collapse();
  }, [setTerminalPanelCollapsed]);

  useEffectOnce(() => {
    const manager = new TerminalManager();
    setTerminalManager(manager);
    return () => {
      manager.dispose();
    };
  });

  return {
    terminals,
    terminalManager,
    terminalTabIndex,
    terminalPanelRef,
    terminalPanelCollapsed,
    setTerminalTabIndex,
    panelTerminals: terminals?.filter(t => t.location === "panel") || [],
    popoutTerminals: terminals?.filter(t => t.location === "popout") || [],
    addTerminal,
    closeTerminal,
    removeTerminal,
    popTerminalOut,
    popTerminalIn,
    expandTerminalPanel,
    collapseTerminalPanel,
  };
};

export const [useTerminals, TerminalsProvider, TerminalsConsumer] = createProvider<
  TerminalsProviderType,
  never
>(initialValues, useTerminalsProviderHook);
