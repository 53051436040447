/* eslint-disable react/jsx-filename-extension */
import { useEffect } from "react";

import { useLocalStorage } from "usehooks-ts";

import { useColorMode, useColorModePreference } from "@chakra-ui/react";

import colorModes from "@/enums/colorModes";
import localStorageKeys from "@/enums/localStorageKeys";
import theme from "@/theme";

export default function useSystemColorMode() {
  const [currentTheme] = useLocalStorage(localStorageKeys.COLOR_MODE_SETTING, theme.config.initialColorMode);
  const { colorMode, toggleColorMode } = useColorMode();
  const preferredColorMode = useColorModePreference();
  useEffect(() => {
    if (currentTheme === colorModes.SYSTEM) {
      if (preferredColorMode !== colorMode) {
        toggleColorMode();
      }
    }
  }, [preferredColorMode, currentTheme, colorMode, toggleColorMode]);

  return;
}
