import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle(parts => ({
  header: {
    p: 4,
  },
  body: {
    p: 4,
    color: "text.lighter",
  },
  footer: {
    p: 4,
  },
}));

const variants = {
  disabled: definePartsStyle(parts => ({
    container: {
      pointerEvents: "none",
      borderColor: "border.base",
      opacity: "0.5",
      bg: "background.darker",
      borderWidth: "1px",
    },
  })),
  selectable: definePartsStyle(parts => ({
    container: {
      ...parts.container,
      _hover: { boxShadow: "outline", cursor: "pointer", textDecoration: "none" },
      _pressed: { borderColor: "border.primary" },
      borderColor: "border.base",
      borderWidth: "1px",
    },
  })),
};

const card = defineMultiStyleConfig({ baseStyle, variants });

export default card;
