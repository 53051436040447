import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  formats: {
    ...dayjs.Ls.en.formats,
    LLL: "MMM D, YYYY h:mm A",
  },
});
