const sizes = {
  sizes: {
    max: "max-content",
    min: "min-content",
    full: "100%",
    "3xs": "224px", //"14rem",
    "2xs": "256px", //"16rem",
    xs: "320px", //"20rem",
    sm: "384px", //"24rem",
    md: "448px", //"28rem",
    lg: "512px", //"32rem",
    xl: "576px", //"36rem",
    "2xl": "672px", //"42rem",
    "3xl": "768px", //"48rem",
    "4xl": "896px", //"56rem",
    "5xl": "1024px", //"64rem",
    "6xl": "1152px", //"72rem",
    "7xl": "1280px", //"80rem",
    "8xl": "1440px", //"90rem",
    container: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
};

export default sizes;
