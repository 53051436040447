const palettes = {
  violet: {
    50: "#e7e4eb",
    100: "#d0c9d6",
    200: "#a295af",
    300: "#776488",
    400: "#4f3563",
    500: "#29063e",
  },
  plum: {
    50: "#f8e8f6",
    100: "#f1d0ed",
    200: "#e2a1dc",
    300: "#d472ca",
    400: "#c643b9",
    500: "#9a2f8f", // plum
    600: "#7d2674",
    700: "#5e1d57",
    800: "#3f133a",
    900: "#1f0a1d",
    950: "#10050f",
  },
  niagara: {
    50: "#ebfffb",
    100: "#d6fff6",
    200: "#adffed",
    300: "#70ffe0",
    400: "#1fffce",
    500: "#00bb93", // niagara
    600: "#00a380",
    700: "#007a60",
    800: "#005240",
    900: "#003D30",
  },
  gray: {
    50: "#f6f6f8", // athens grey
    100: "#efedf2", // grey haze
    150: "#eae8ed", // icycle
    200: "#e1dfe5", // iron
    250: "#d1ced7", // ghost
    300: "#c0bdc7", // ash
    400: "#a09ca8", // mist
    500: "#7f7b87", // topaz
    600: "#595461", // gravel
    700: "#413c4a", // ship grey
    800: "#2c2d31",
    900: "#1c1d21",
  },
  blue: {
    500: "#4572CC",
  },
  water: {
    50: "#edeffb",
    100: "#dbdef7",
    200: "#b9beef",
    300: "#989ee5",
    400: "#7a7dd9",
    500: "#5e5bcd", // water
    600: "#444198",
    700: "#2b2966",
    800: "#141338",
    900: "#03030f",
  },
  brick: {
    50: "#f8ecea",
    100: "#f1d9d5",
    200: "#e2b4ad",
    300: "#d19086",
    400: "#bf6b60",
    500: "#ab453a", // brick
    600: "#7e3128",
    700: "#541e18",
    800: "#2d0c09",
    900: "#0b0201",
  },
  mud: {
    50: "#f2f1e8",
    100: "#e5e2d2",
    200: "#ccc6a6",
    300: "#b3aa7a",
    400: "#9b8f4d",
    500: "#847412", // mud
    600: "#60540a",
    700: "#3f3704",
    800: "#201b01",
    900: "#060500",
  },
  gold: {
    50: "#fbf7ed",
    100: "#f7eedb",
    200: "#efddb6",
    300: "#e7cc91",
    400: "#dfbb68",
    500: "#d7a936", // gold
    600: "#9f7c25",
    700: "#6b5316",
    800: "#3b2c08",
    900: "#110a01",
  },
  success: {
    500: "#5eb817",
  },
  error: {
    500: "#de592c",
  },
};

const colorThemes = {
  terminal: {
    foreground: "#F8F8F8",
    background: palettes.gray[800],
    selection: "#5DA5D533",
    black: "#1E1E1D",
    brightBlack: "#262625",
    red: "#CE5C5C",
    brightRed: "#FF7272",
    green: "#5BCC5B",
    brightGreen: "#72FF72",
    yellow: "#CCCC5B",
    brightYellow: "#FFFF72",
    blue: "#5E5BCD",
    brightBlue: "#7279FF",
    magenta: "#BC5ED1",
    brightMagenta: "#E572FF",
    cyan: "#5DA5D5",
    brightCyan: "#72F0FF",
    white: "#F8F8F8",
    brightWhite: "#FFFFFF",
  },
  yaml: {
    background: palettes.gray[50],
    foreground: palettes.gray[600],
    caret: palettes.gray[600],
    selection: palettes.gray[200],
    selectionMatch: palettes.gray[200],
    lineHighlight: `${palettes.gray[200]}6e`,
    gutterBackground: palettes.gray[50],
    gutterForeground: palettes.gray[300],
    gutterBorder: "#ffffff00",
    key: palettes.plum[500],
    symbol: palettes.niagara[600],
    value: palettes.niagara[600],
    comment: palettes.gray[400],
    gutterText: palettes.gray[500],
    keyword: palettes.water[400],
  },
  _yaml: {
    background: palettes.gray[900],
    foreground: "#69b1d3",
    caret: "#69b1d3",
    selection: palettes.gray[700],
    selectionMatch: palettes.gray[700],
    lineHighlight: `${palettes.gray[800]}6e`,
    gutterBackground: palettes.gray[900],
    gutterForeground: "RGBA(255, 255, 255, 0.50)",
    gutterBorder: "#ffffff00",
    key: "#d94c58",
    symbol: "#cbcdd2",
    value: "#69b1d3",
    comment: "#696b77",
    gutterText: "#494b53",
    keyword: "#e0823f",
  },
};

const colors = {
  ...palettes,
  ...colorThemes,
};

export default colors;
