import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    fontSize: "fs.200",
    color: "text.body",
    fontWeight: "medium",
    mb: 1,
  },
});
