import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  addon: {
    bg: "background.base",
    color: "plum.500",
    borderColor: "border.lighter",
  },
  field: {
    color: "text.darker",
    borderColor: "border.lighter",
    fontSize: "fs.100",
    fontWeight: "medium",
    _dark: {
      bg: "gray.900",
      _disabled: {
        opacity: 0.4,
      },
      _hover: {
        borderColor: "black",
      },
      _focus: {
        borderColor: "text.primary",
      },
    },
    _focus: {
      borderColor: "border.primary",
    },
    _focusVisible: {
      boxShadow: "outline",
    },
    _placeholder: {
      color: "text.lighter",
    },
    _disabled: {
      bg: "gray.50",
      color: "text.lighter",
      borderColor: "border.lighter",
      opacity: 1,
    },
  },
});

export const input = defineMultiStyleConfig({
  defaultProps: {
    size: "lg",
    variant: "outline",
  },
  variants: { outline },
});

export default input;
