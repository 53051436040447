import { IncomingMessage } from "http";

import {
  DocumentMenu_DocumentInterfaceFragment,
  Document_DocumentInterfaceFragment,
  Product,
} from "@/__generated__/graphql";

export const craFrontendPath = (path: string) => `${process.env.NEXT_PUBLIC_CRA_FRONTEND_BASE_URL}${path}`;
export const workspacePath = (pathname: string, workspaceId: string) =>
  `/workspaces/${workspaceId}/${pathname}`.replace(/([^:]\/)\/+/g, "$1");

// Invites
export const inviteAcceptPath = (uuid: string) => `/invites/${uuid}/accept`;

// Standalone Datasets related paths
const productPath = (product?: Product) => {
  switch (product) {
    case Product.StandaloneDatasets:
      return "instantdatasets";
    case Product.ReleaseAi:
      return "ai";
    case Product.Release:
    default:
      return "configuration";
  }
};

// Applications
export const applicationsPath = ({ workspaceId, action }: { workspaceId: string; action?: string }) =>
  `/workspaces/${workspaceId}/applications${action ? `/${action}` : ""}`;
export const applicationPath = ({
  workspaceId,
  applicationId,
  action,
}: {
  workspaceId: string;
  applicationId: string;
  action?: string;
}) => workspacePath(`/applications/${applicationId}${action ? `/${action}` : ""}`, workspaceId);

export const applicationSettingsPath = ({
  workspaceId,
  applicationId,
  action,
}: {
  workspaceId: string;
  applicationId: string;
  action?: string;
}) =>
  applicationPath({
    workspaceId,
    applicationId,
    action: `settings${action ? `/${action}` : ""}`,
  });
export const applicationFileMountsPath = ({
  workspaceId,
  applicationId,
  action,
}: {
  workspaceId: string;
  applicationId: string;
  action?: string;
}) =>
  applicationPath({
    workspaceId,
    applicationId,
    action: `settings/file_mounts${action ? `/${action}` : ""}`,
  });

// Documents - Application Template / Environment Variables & Environment Configuration / Environment Variables
export const templatePath = ({
  workspaceId,
  applicationId,
  templateId,
}: {
  workspaceId: string;
  applicationId: string;
  templateId: string;
}) => `/workspaces/${workspaceId}/applications/${applicationId}/settings/templates/${templateId}`;

export const configurationPath = ({
  workspaceId,
  applicationId,
  environmentId,
  configurationId,
}: {
  workspaceId: string;
  applicationId: string;
  environmentId: string;
  configurationId: string;
}) =>
  `/workspaces/${workspaceId}/applications/${applicationId}/environments/${environmentId}/settings/configurations/${configurationId}`;

export const applicationDocumentComparePath = ({
  workspaceId,
  applicationId,
  leftTemplateId,
  rightTemplateId,
}: {
  workspaceId: string;
  applicationId: string;
  leftTemplateId: string;
  rightTemplateId: string;
}) =>
  `/workspaces/${workspaceId}/applications/${applicationId}/settings/templates/compare/${leftTemplateId}...${rightTemplateId}`;

export const environmentDocumentComparePath = ({
  workspaceId,
  applicationId,
  environmentId,
  leftTemplateId,
  rightTemplateId,
}: {
  workspaceId: string;
  applicationId: string;
  environmentId: string;
  leftTemplateId: string;
  rightTemplateId: string;
}) =>
  `/workspaces/${workspaceId}/applications/${applicationId}/environments/${environmentId}/settings/configurations/compare/${leftTemplateId}...${rightTemplateId}`;

type PathForDocumentProps = {
  workspaceId: string;
  applicationId: string;
  environmentId?: string;
  documentId: string;
};

export function pathForDocument({
  workspaceId,
  applicationId,
  environmentId,
  documentId,
}: PathForDocumentProps) {
  // If we ever add other types of objects that adhere to DocumentInterface we can update this to be based
  // off the __typename
  if (environmentId) {
    return configurationPath({ workspaceId, applicationId, environmentId, configurationId: documentId });
  }
  return templatePath({ workspaceId, applicationId, templateId: documentId });
}

type ComparePathForDocumentProps = {
  workspaceId: string;
  applicationId: string;
  environmentId?: string;
  document: DocumentMenu_DocumentInterfaceFragment | Document_DocumentInterfaceFragment;
};

export function comparePathForDocument({
  workspaceId,
  applicationId,
  environmentId,
  document,
}: ComparePathForDocumentProps) {
  // If we ever add other types of objects that adhere to DocumentInterface we can update this to be based
  // off the __typename
  if (environmentId) {
    return environmentDocumentComparePath({
      workspaceId,
      applicationId,
      environmentId,
      leftTemplateId: document.previous?.databaseId ?? "",
      rightTemplateId: document.databaseId,
    });
  }
  return applicationDocumentComparePath({
    workspaceId,
    applicationId,
    leftTemplateId: document.previous?.databaseId ?? "",
    rightTemplateId: document.databaseId,
  });
}

// Builds
export const buildsPath = (workspaceId: string, applicationId: string) =>
  `/workspaces/${workspaceId}/applications/${applicationId}/builds`;
export const buildPath = ({
  workspaceId,
  applicationId,
  buildId,
  environmentId,
}: {
  workspaceId: string;
  applicationId: string;
  buildId: string;
  environmentId?: string;
}) => {
  const basePath = `/workspaces/${workspaceId}/applications/${applicationId}`;
  const environmentPath = environmentId ? `/environments/${environmentId}` : "";
  return `${basePath}${environmentPath}/builds/${buildId}`;
};
export const buildCreatePath = ({
  workspaceId,
  applicationId,
}: {
  workspaceId: string;
  applicationId: string;
}) => `/workspaces/${workspaceId}/applications/${applicationId}/builds/create`;

// Cloud Integrations
export const cloudIntegrationsPath = ({
  workspaceId,
  action,
  product,
}: {
  workspaceId: string;
  action?: string;
  product?: Product;
}) => workspacePath(`/${productPath(product)}/cloud_integrations${action ? `/${action}` : ""}`, workspaceId);
export const cloudIntegrationPath = ({
  workspaceId,
  cloudIntegrationId,
  action,
  product,
}: {
  workspaceId: string;
  cloudIntegrationId: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(
    `${productPath(product)}/cloud_integrations/${cloudIntegrationId}${action ? `/${action}` : ""}`,
    workspaceId
  );
export const cloudIntegrationDatasetPath = ({
  workspaceId = "",
  cloudIntegrationId,
  datasetId,
  action,
  product,
}: {
  workspaceId?: string;
  cloudIntegrationId?: string;
  datasetId: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(
    `${productPath(product)}/cloud_integrations/${cloudIntegrationId}/datasets/${datasetId}${
      action ? `/${action}` : ""
    }`,
    workspaceId
  );

// Datasets
export const datasetsPath = ({
  workspaceId,
  action,
  product,
}: {
  workspaceId: string;
  action?: string;
  product?: Product;
}) => workspacePath(`${productPath(product)}/datasets${action ? `/${action}` : ""}`, workspaceId);
export const datasetPath = ({
  workspaceId,
  datasetId,
  action,
  product,
}: {
  workspaceId: string;
  datasetId: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(`${productPath(product)}/datasets/${datasetId}${action ? `/${action}` : ""}`, workspaceId);
export const databasePath = ({
  workspaceId,
  datasetId,
  databaseId,
  action,
  product,
}: {
  workspaceId: string;
  datasetId: string;
  databaseId: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(
    `${productPath(product)}/datasets/${datasetId}/databases/${databaseId}${action ? `/${action}` : ""}`,
    workspaceId
  );

// Clusters
export const clustersPath = ({
  workspaceId,
  action,
  product,
}: {
  workspaceId: string;
  action?: string;
  product?: Product;
}) => workspacePath(`/${productPath(product)}/clusters${action ? `/${action}` : ""}`, workspaceId);

export const clusterPath = ({
  workspaceId,
  clusterId,
  action,
  product,
}: {
  workspaceId: string;
  clusterId: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(`${productPath(product)}/clusters/${clusterId}${action ? `/${action}` : ""}`, workspaceId);

// Deploys
export const deploysPath = ({ workspaceId, appId }: { workspaceId: string; appId: string }) =>
  `/workspaces/${workspaceId}/applications/${appId}/deploys`;
export const deployPath = ({
  workspaceId,
  applicationId,
  environmentId,
  deployId,
}: {
  workspaceId: string;
  applicationId: string;
  environmentId: string;
  deployId: string;
}) =>
  `/workspaces/${workspaceId}/applications/${applicationId}/environments/${environmentId}/deploys/${deployId}`;

// Domains
export const domainsPath = ({ workspaceId, action }: { workspaceId: string; action?: string }) =>
  workspacePath(`configuration/domains${action ? `/${action}` : ""}`, workspaceId);

export const domainPath = ({
  workspaceId,
  domainId,
  action,
}: {
  workspaceId: string;
  domainId: string;
  action?: string;
}) => workspacePath(`configuration/domains/${domainId}${action ? `/${action}` : ""}`, workspaceId);

// Delivery Installation

export const deliveryInstallationPath = ({
  workspaceId,
  deliveryInstallationId,
  action,
}: {
  workspaceId: string;
  deliveryInstallationId: string;
  action?: string;
}) =>
  workspacePath(`delivery/installations/${deliveryInstallationId}${action ? `/${action}` : ""}`, workspaceId);

// Jobs
export const jobsPath = ({
  applicationId,
  workspaceId,
  action,
}: {
  applicationId: string;
  workspaceId: string;
  action?: string;
}) => applicationPath({ action: `/jobs${action ? `/${action}` : ""}`, applicationId, workspaceId });
export const jobPath = ({
  workspaceId,
  applicationId,
  environmentId,
  action,
}: {
  applicationId: string;
  environmentId: string;
  workspaceId: string;
  action?: string;
}) =>
  applicationPath({
    action: `/jobs/${environmentId}${action ? `/${action}` : ""}`,
    applicationId,
    workspaceId,
  });

// Environments
export const environmentsPath = ({
  applicationId,
  workspaceId,
  action,
}: {
  applicationId: string;
  workspaceId: string;
  action?: string;
}) => applicationPath({ action: `/environments${action ? `/${action}` : ""}`, applicationId, workspaceId });
export const environmentPath = ({
  workspaceId,
  applicationId,
  environmentId,
  action,
}: {
  applicationId: string;
  environmentId: string;
  workspaceId: string;
  action?: string;
}) =>
  applicationPath({
    action: `/environments/${environmentId}${action ? `/${action}` : ""}`,
    applicationId,
    workspaceId,
  });

// Environment Presets
export const environmentPresetsPath = ({ workspaceId, action }: { workspaceId: string; action?: string }) =>
  workspacePath(`/configuration/environment_presets${action ? `/${action}` : ""}`, workspaceId);

export const environmentPresetPath = ({
  workspaceId,
  environmentPresetId,
  action,
}: {
  workspaceId: string;
  environmentPresetId: string;
  action?: string;
}) =>
  workspacePath(
    `/configuration/environment_presets/${environmentPresetId}${action ? `/${action}` : ""}`,
    workspaceId
  );

// Environment Handles
export const environmentHandlePath = ({
  workspaceId,
  environmentPresetId,
  environmentHandleId,
}: {
  workspaceId: string;
  environmentPresetId: string;
  environmentHandleId: string;
}) =>
  workspacePath(
    `/configuration/environment_presets/${environmentPresetId}/presets/${environmentHandleId}`,
    workspaceId
  );

export const instancePath = ({
  workspaceId,
  applicationId,
  environmentId,
  instanceId,
  action,
}: {
  workspaceId: string;
  applicationId: string;
  environmentId: string;
  instanceId: string;
  action?: string;
}) =>
  workspacePath(
    `applications/${applicationId}/environments/${environmentId}/resources/${instanceId}${
      action ? `/${action}` : ""
    }`,
    workspaceId
  );

// External Registries
export const externalRegistriesPath = ({ workspaceId, action }: { workspaceId: string; action?: string }) =>
  workspacePath(`configuration/external_registries${action ? `/${action}` : ""}`, workspaceId);

export const externalRegistryPath = ({
  workspaceId,
  externalRegistryId,
  action,
}: {
  workspaceId: string;
  externalRegistryId: string;
  action?: string;
}) =>
  workspacePath(
    `configuration/external_registries/${externalRegistryId}${action ? `/${action}` : ""}`,
    workspaceId
  );

// Integrations
export const integrationsPath = ({
  workspaceId,
  action,
  product,
}: {
  workspaceId: string;
  action?: string;
  product?: Product;
}) => workspacePath(`${productPath(product)}/integrations${action ? `/${action}` : ""}`, workspaceId);

export const integrationInstancePath = ({
  workspaceId,
  integrationType,
  integrationId,
  action,
  product,
}: {
  workspaceId: string;
  integrationId: string;
  integrationType: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(
    `${productPath(product)}/integrations/${integrationType.toLowerCase()}/${integrationId}${
      action ? `/${action}` : ""
    }`,
    workspaceId
  );

export const integrationPath = ({
  workspaceId,
  integrationType,
  product,
}: {
  workspaceId: string;
  integrationType: string;
  product?: Product;
}) => workspacePath(`${productPath(product)}/integrations/${integrationType.toLowerCase()}`, workspaceId);

export const createIntegrationPath = ({
  workspaceId,
  integrationType,
  product,
}: {
  workspaceId: string;
  integrationType: string;
  product?: Product;
}) =>
  workspacePath(`${productPath(product)}/integrations/${integrationType.toLowerCase()}/create`, workspaceId);

// AI Configurations

export const aiConfigurationsPath = ({
  workspaceId,
  action,
  product,
}: {
  workspaceId: string;
  action?: string;
  product?: Product;
}) => workspacePath(`${productPath(product)}/ai_configurations${action ? `/${action}` : ""}`, workspaceId);

export const aiConfigurationPath = ({
  workspaceId,
  aiConfigurationId,
  action,
  product,
}: {
  workspaceId: string;
  aiConfigurationId: string;
  action?: string;
  product?: Product;
}) =>
  workspacePath(
    `${productPath(product)}/ai_configurations/${aiConfigurationId}${action ? `/${action}` : ""}`,
    workspaceId
  );

// Settings
export const settingsPath = ({ workspaceId }: { workspaceId: string }) =>
  workspacePath(`/settings`, workspaceId);

export const membersPath = ({ workspaceId }: { workspaceId: string }) =>
  settingsPath({ workspaceId }) + "/members";

export const sendInvitePath = ({ workspaceId }: { workspaceId: string }) =>
  settingsPath({ workspaceId }) + "/members/invite";

export const subscriptionPath = ({ workspaceId }: { workspaceId: string }) =>
  settingsPath({ workspaceId }) + "/subscription";

export const pathTo = (
  path: string,
  options: { req?: IncomingMessage; search?: Record<string, string>; keepQuery?: boolean } = {}
) => {
  const { req } = options;
  const isSsr = typeof window === "undefined";
  let baseUri = "#";
  if (isSsr && !req) return baseUri;
  else if (isSsr && req) {
    baseUri = new URL(req.url ?? "/", `${req.headers["x-forwarded-proto"] ?? "https"}://${req.headers.host}`)
      .href;
  } else {
    baseUri = document.baseURI;
  }

  const current = new URL(`${baseUri}/`);
  const newPath = new URL(path, current.href);
  let searchParams = new URLSearchParams(options?.search || {});
  if (options?.keepQuery) {
    searchParams = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...Object.fromEntries(new URLSearchParams(current.search)),
    });
  }
  newPath.search = searchParams.toString();
  return newPath.href;
};

// SSH Keys

export const sshKeysPath = ({ workspaceId }: { workspaceId: string }) =>
  workspacePath("configuration/ssh_keys", workspaceId);

// Secrets

export const secretsPath = ({ workspaceId }: { workspaceId: string }) =>
  workspacePath("configuration/secrets", workspaceId);

// Namespaces

export const namespacePath = ({
  workspaceId,
  applicationId,
  environmentId,
  namespaceId,
}: {
  applicationId: string;
  environmentId: string;
  workspaceId: string;
  namespaceId: string;
}) =>
  environmentPath({
    workspaceId,
    applicationId,
    environmentId,
    action: `/namespaces/${namespaceId}`,
  });

// Node Groups

export const createNodeGroupPath = ({ workspaceId, clusterId }: { workspaceId: string; clusterId: string }) =>
  workspacePath(`configuration/clusters/${clusterId}/node_groups/create`, workspaceId);

export const nodeGroupPath = ({
  workspaceId,
  clusterId,
  nodeGroupId,
  action,
}: {
  workspaceId: string;
  clusterId: string;
  nodeGroupId: string;
  action?: string;
}) =>
  workspacePath(
    `configuration/clusters/${clusterId}/node_groups/${nodeGroupId}${action ? `/${action}` : ""}`,
    workspaceId
  );

// Dependency Schedules
export const applicationDependencySchedulePath = ({
  workspaceId,
  applicationId,
}: {
  workspaceId: string;
  applicationId: string;
}) => applicationPath({ workspaceId, applicationId, action: "settings/pausing_schedule" });
