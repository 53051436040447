import { ApolloLink, FetchResult } from "@apollo/client";
import { toast } from "sonner";

import { UserError } from "@/__generated__/graphql";
import { isObject } from "@/utils/typeGuards";

type TypenamedError = { __typename?: string } & UserError;
type TypenamedResult = { __typename?: string } & { [key: string]: { userErrors: Array<TypenamedError> } };

// Log any GraphQL errors or network error that occurred
const userErrorLink = new ApolloLink((operation, forward) => {
  // Called after server responds
  return forward(operation).map((response: FetchResult<TypenamedResult>) => {
    if (!isObject(response.data) || response.data.__typename !== "Mutation") return response;

    for (const key of Object.keys(response.data)) {
      // User Errors
      const userErrors = response.data[key].userErrors as Array<TypenamedError>;
      if (Array.isArray(response.data[key].userErrors) && response.data[key].userErrors.length) {
        // Do not handle errors here if found

        for (const userError of userErrors) {
          switch (userError.__typename) {
            // This is where you can put errors that are handled generically.
            case "TemplateLineError":
            case "ArgumentError":
              return response;

            // case "YourCustomError":

            default:
              toast.error("[1013] Unknown error", {
                description: "An unknown error has occurred. Please contact support if this error continues.",
              });
          }
        }
      }
    }

    return response;
  });
});

export default userErrorLink;
