import { NextParsedUrlQuery } from "next/dist/server/request-meta";

const MODEL_NAMES_FOR_TYPES: Record<string, string> = {
  aiConfigurationId: "Ai::BotConfig",
  applicationId: "App",
  buildId: "ReposV2::Build",
  cloudIntegrationId: "ClustersV2::CloudIntegration",
  cloudProviderType: "ClustersV2::CloudProvider",
  clusterId: "ClustersV2::Cluster",
  databaseId: "PoolItem",
  datasetId: "Pools::Databases::Pool",
  deliveryInstallationId: "ClustersV2::OnPremiseInstallation",
  deployId: "ConfigsV2::Pipelines::Space",
  domainId: "Domain",
  environmentId: "ConfigsV2::Space",
  environmentPresetId: "Pools::EnvironmentHandles::Pool",
  externalRegistryId: "PrivateRegistry",
  instanceId: "Spaces::Pod",
  integrationId: "Integration",
  integrationSlug: "Integration",
  namespaceId: "ConfigsV2::Namespace",
  nodeGroupId: "ClustersV2::NodeGroupTemplate",
  presetId: "PoolItem",
  projectMappingId: "ProjectMapping",
  templateId: "ConfigsV2::SerializedConfigs::Space",
  workspaceId: "Account",
};

export function generateCacheId(type: string, pathIds: NextParsedUrlQuery): string | undefined {
  const modelName = MODEL_NAMES_FOR_TYPES[type];
  if (!modelName) return;

  return btoa(`gid://spacedust/${modelName}/${pathIds[type]}`).replace(/=+$/, "");
}
