import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const subtle = defineStyle(props => {
  if (props.colorScheme === "clear") {
    return {
      background: "none",
    };
  }
  return {};
});

const emphasis = defineStyle({
  bg: "background.base",
  border: "1px",
  borderColor: "border.lightest",
  borderRadius: "md",
  color: "text.primary",
  display: "inline-block",
  fontFamily: "'Jetbrains Mono', sans-serif",
  fontSize: "fs.100",
  letterSpacing: "ls.50",
  fontWeight: "medium",
  maxW: "100%",
  mb: "-1px",
  minWidth: "0",
  overflow: "hidden",
  p: 2,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export default defineStyleConfig({
  variants: { subtle, emphasis },
});
