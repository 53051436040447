import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);
const baseStyle = definePartsStyle({
  tabpanel: {
    px: 0,
  },
  tab: {
    fontFeatureSettings: "inherit",
    fontWeight: "medium",
    _hover: {
      textDecoration: "none",
    },
    _selected: {
      color: "text.primary",
    },
  },
  tablist: {
    color: "text.darker",
  },
});

export default defineMultiStyleConfig({ baseStyle });
