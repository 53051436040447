import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    color: "text.darkest",
    fontWeight: "bold",
    letterSpacing: "ls.100",
    lineHeight: "base",
  },

  sizes: {
    heading1: {
      lineHeight: "short",
      fontSize: "fs.900",
    },
    heading2: {
      fontSize: "fs.800",
    },
    heading3: {
      lineHeight: "44px",
      fontSize: "fs.700",
    },
    heading4: {
      lineHeight: "40px",
      fontSize: "fs.600",
    },
    heading5: {
      lineHeight: "28px",
      fontSize: "fs.500",
    },
    heading6: {
      lineHeight: "24pt",
      fontSize: "fs.400",
    },
  },

  variants: {
    subsection: {
      color: "text.lighter",
    },
  },
});
