import { QueryResult, useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";

import { getFragmentData, graphql } from "@/__generated__";
import { QCurrentUserProviderQuery, QCurrentUserProviderQueryVariables } from "@/__generated__/graphql";
import createProvider from "@/providerFactories/createProvider";

const QCurrentUserProvider = graphql(`
  query QCurrentUserProvider {
    currentUser {
      ...CurrentUserProvider_User
    }
  }
`);

const CurrentUserProvider_User = graphql(`
  fragment CurrentUserProvider_User on User {
    id
    databaseId
    confirmed
    fullName
    isEmployee
    featureFlags {
      key
      enabled
    }
    emailAddresses {
      nodes {
        id
        databaseId
        email
      }
    }
    primaryEmailAddress {
      id
      databaseId
      email
    }
    settings {
      showNotifications
      defaultEnvironmentSortingKey
      defaultEnvironmentSortingOrder
    }
    ...UserAvatar_User
  }
`);

type CurrentUserProviderType = {
  currentUserResult?: QueryResult<QCurrentUserProviderQuery, QCurrentUserProviderQueryVariables>;
  currentUser?: ResultOf<typeof CurrentUserProvider_User>;
};

const useCurrentUserProviderHook = () => {
  const currentUserResult = useQuery(QCurrentUserProvider);
  const currentUser =
    getFragmentData(CurrentUserProvider_User, currentUserResult?.data?.currentUser) ?? undefined;

  return {
    currentUserResult,
    currentUser: currentUser,
  };
};

export const [useCurrentUser, CurrentUserProvider, CurrentUserConsumer] = createProvider<
  CurrentUserProviderType,
  unknown
>({}, useCurrentUserProviderHook);
