import { createHttpLink } from "@apollo/client";

const apolloLink = (cookie?: string, ssrMode?: boolean) => {
  const baseUrl = process.env.NEXT_PUBLIC_BACKEND_BASE_URL ?? "http://localhost:3000";
  const spacedustEnv = process.env.SPACEDUST_ENV;
  return createHttpLink({
    uri:
      spacedustEnv && spacedustEnv != "local" && ssrMode
        ? "http://backend:3000/graphql"
        : `${baseUrl}/graphql`,
    credentials: "include",
    headers: {
      ...(cookie && { cookie }),
    },
  });
};

export default apolloLink;
