export default function SpacedustEphemeralEnvironment() {
  return (
    <div
      style={{
        background: "repeating-linear-gradient(-45deg, #a9a9a9, #a9a9a9 15px, #D5D5D5 10px, #D5D5D5 30px)",
        borderTop: "1px solid #a9a9a9",
        position: "fixed",
        bottom: "0",
        width: "100%",
        zIndex: "9998",
      }}
    >
      <p
        style={{
          color: "#3E154D",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "bold",
          textAlign: "center",
          verticalAlign: "middle",
          background: "#a9a9a9",
          border: "2px solid #3E154D",
          borderRadius: "20px",
          width: "230px",
          margin: "0 auto",
        }}
      >
        &#9888; &#8226; <i>EPHEMERAL ENVIRONMENT</i> &#8226; &#9888;
      </p>
    </div>
  );
}
