import { useRouter } from "next/router";

const useReadySearchParams = () => {
  const { isReady, query } = useRouter();

  const definedParams: Record<string, string> = {};
  if (isReady) {
    Object.keys(query).forEach(key => {
      const value = query[key];
      if (value) definedParams[key] = Array.isArray(value) ? value[0] : value;
    });
  }

  return definedParams;
};

export default useReadySearchParams;
