import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const roundedSquare = definePartsStyle({
  badge: {
    bg: "gray.500",
    border: "2px solid",
  },
  container: {
    borderRadius: "md",
    img: {
      borderRadius: "md",
    },
  },
  excessLabel: {
    bg: "gray.800",
    color: "white",
    borderRadius: "md",
    border: "2px solid",

    _dark: {
      bg: "gray.400",
      color: "gray.900",
    },
  },
});

export default defineMultiStyleConfig({
  variants: { roundedSquare },
});
