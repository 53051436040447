/**
 * BaseLayout wraps the entire app.
 */

import { Box } from "@chakra-ui/react";

import Rollbar from "@/components/Rollbar";
import Tracking from "@/components/shared/Tracking";
import SpacedustEphemeralEnvironment from "@/components/SpacedustEphemeralEnvironment";
import useSystemColorMode from "@/hooks/useSystemColorMode";
import { BreadcrumbsProvider } from "@/providers/useBreadcrumbs";
import { CurrentUserProvider } from "@/providers/useCurrentUser";
import { LayoutDataConsumer, LayoutDataProvider, LayoutDataProviderType } from "@/providers/useLayoutData";
import { LayoutSlotsProvider } from "@/providers/useLayoutSlots";
import { TerminalsProvider } from "@/providers/useTerminals";
import { WorkspaceProvider } from "@/providers/useWorkspace";
import Datadog from "@/services/Datadog";
import Freshpaint from "@/services/Freshpaint";
import { wrapWith } from "@/utils/wrapWith";

export const LayoutProviders = wrapWith(
  WorkspaceProvider,
  CurrentUserProvider,
  LayoutSlotsProvider,
  LayoutDataProvider,
  BreadcrumbsProvider,
  TerminalsProvider
);

function BaseLayout({
  children,
}: Record<never, never> & {
  children: (layoutData: LayoutDataProviderType) => React.ReactNode;
}) {
  // handle cases where we try to match the os setting
  useSystemColorMode();

  return (
    <>
      {String(process.env.NEXT_PUBLIC_SPACEDUST_EPHEMERAL_ENVIRONMENT).toLowerCase() === "true" && (
        <SpacedustEphemeralEnvironment />
      )}
      <LayoutProviders>
        <Tracking>
          {props => (
            <Box h="full">
              <Datadog {...props} />
              <Freshpaint {...props} />
              <Rollbar {...props}>
                <LayoutDataConsumer>{layoutData => children(layoutData)}</LayoutDataConsumer>
              </Rollbar>
            </Box>
          )}
        </Tracking>
      </LayoutProviders>
    </>
  );
}

export default BaseLayout;
