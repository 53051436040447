const textStyles = {
  help: {
    fontSize: "fs.50",
    color: "text.lighter",
  },
  subtitle: {
    fontSize: "0.8em",
    color: "text.lighter",
  },
  subheading: {
    fontSize: "1.1em",
    fontWeight: "light",
  },
  overlineText: {
    fontWeight: "normal",
    fontSize: "fs.50",
    letterSpacing: "ls.250",
    textTransform: "uppercase",
  },
  release: {
    color: "text.tertiary",
    display: "inline",
    fontWeight: "700",
  },
  subtle: {
    fontWeight: "semibold",
  },
  intense: {
    fontWeight: "bold",
  },
};

export default textStyles;
