import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { sizes } from "@/theme/components/text";

export default defineStyleConfig({
  baseStyle: props => {
    const { colorScheme: color } = props;

    return {
      fontWeight: "medium",
      color: mode(`${color}.500`, `${color}.500`)(props),
      letterSpacing: "ls.50",
    };
  },
  defaultProps: {
    colorScheme: "blue",
  },
  variants: {
    buttonWrapper: {
      textDecoration: "none",
      _hover: {
        textDecoration: "none",
      },
    },
  },
  sizes,
});
