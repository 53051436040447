import { SystemStyleObjectRecord } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

import colors from "./foundations/colors";

const layerStyles: SystemStyleObjectRecord = {
  highlightBlock: {
    bg: "rgba(255, 249, 234, 1)",
    borderRadius: "8px",
    _dark: { bg: "rgba(255, 249, 234, 0.2)" },
  },
  frostBackground: {
    bg: transparentize("white", 0.4)({ colors }),

    backdropFilter: "blur(16px)",
    _dark: {
      bg: transparentize("gray.800", 0.4)({ colors }),
    },
  },
};

export default layerStyles;
