import { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { ApolloClient, useApolloClient } from "@apollo/client";
import { FragmentDefinitionNode } from "graphql";
import _ from "lodash";
import { NextParsedUrlQuery } from "next/dist/server/request-meta";
import { useRouter } from "next/router";

import createProvider from "@/providerFactories/createProvider";
import { generateCacheId } from "@/utils/generateCacheId";

import { LAYOUT_DATA_FRAGMENTS, useLayoutData } from "./useLayoutData";

const LABEL_FINDERS: {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [key: string]: (arg0: any, key: string, searchParams: NextParsedUrlQuery) => string;
} = {
  buildId: result => {
    // @todo remove this when we give build a relative number name
    return result?.unhashedDatabaseId;
  },
  clusterId: result => {
    return result?.context;
  },
  deployId: result => {
    return result?.unhashedDatabaseId;
  },
  databaseId: result => {
    const poolable = result?.poolable;
    return poolable?.name || "";
  },
  externalRegistryId: result => {
    return result?.host;
  },
  presetId: result => {
    const poolable = result?.poolable;
    return poolable?.handle || "";
  },
  integrationSlug: (result, key) => {
    if (result) return result.name;
    switch (key) {
      case "open_ai":
        return "OpenAI";
      case "planet_scale":
        return "PlanetScale";
      case "slack":
      case "vercel":
      case "datadog":
        return _.startCase(key);
      default:
        return "";
    }
  },
  cloudProviderType: (_result, key) => {
    switch (key) {
      case "aws":
      case "gcp":
        return key.toUpperCase();
      case "release":
        return "Release";
      default:
        return "";
    }
  },
  memberType: (_result, key) => {
    return _.startCase(key);
  },
};

const lookupLabel = (
  apolloClient: ApolloClient<unknown>,
  searchParams: NextParsedUrlQuery,
  key: string,
  type: string
) => {
  const finder = LABEL_FINDERS[type];
  const fragment = LAYOUT_DATA_FRAGMENTS[type as keyof typeof LAYOUT_DATA_FRAGMENTS];
  const cacheId = generateCacheId(type, searchParams);
  if (!cacheId) return;
  const query = {
    id: cacheId,
    fragment: fragment,
    fragmentName: fragment && (fragment.definitions[0] as FragmentDefinitionNode).name.value,
  };
  const result = cacheId && fragment ? apolloClient.readFragment(query) : null;
  return finder ? finder(result, key, searchParams) : result?.name;
};

export type BreadcrumbData = {
  breadcrumb: string | undefined;
  href: string;
};

type BreadcrumbsProviderType = {
  breadcrumbs: BreadcrumbData[];
  updateBreadcrumbs: () => void;
};

export const initialData: BreadcrumbsProviderType = {
  breadcrumbs: [] as BreadcrumbData[],
} as BreadcrumbsProviderType;

const useBreadcrumbsProviderHook = () => {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const layoutData = useLayoutData();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbData[]>([]);

  const updateBreadcrumbs = useCallback(() => {
    const linkPathTemplate = router.pathname.split("?")[0].split("/").slice(0);
    const linkPath = router.asPath.split("?")[0].split("/").slice(0);
    linkPath.shift();
    linkPathTemplate.shift();
    const pathArray = linkPath.map(function (path, i) {
      const match = linkPathTemplate[i]?.match(/\[(.*)]/);
      return {
        breadcrumb: match ? lookupLabel(apolloClient, router.query, path, match[1]) : _.startCase(path),
        href: "/" + linkPath.slice(0, i + 1).join("/"),
      };
    });
    setBreadcrumbs(pathArray);
  }, [apolloClient, router, setBreadcrumbs]);

  useEffect(() => {
    if (router && layoutData) {
      updateBreadcrumbs();
    }
  }, [layoutData, router, updateBreadcrumbs]);

  return {
    breadcrumbs,
    updateBreadcrumbs,
  };
};

export const [useBreadcrumbs, BreadcrumbsProvider, BreadcrumbsConsumer] = createProvider<
  BreadcrumbsProviderType,
  PropsWithChildren<unknown>
>(initialData, useBreadcrumbsProviderHook, "Breadcrumbs");
