
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AiConfigurationsCreateError": [
      "ArgumentError"
    ],
    "AiConfigurationsDeleteError": [
      "ArgumentError"
    ],
    "AiConfigurationsUpdateError": [
      "ArgumentError"
    ],
    "ApplicationDocumentUnion": [
      "ApplicationTemplate",
      "ApplicationTemplateEnvironmentVariables"
    ],
    "ApplicationTemplatesCreateEnvironmentVariablesError": [
      "OperationError",
      "TemplateLineError"
    ],
    "ApplicationTemplatesCreateError": [
      "OperationError",
      "TemplateLineError"
    ],
    "AppsActivateError": [
      "ArgumentError"
    ],
    "AppsCloneError": [
      "ArgumentError"
    ],
    "AppsCreateError": [
      "ArgumentError"
    ],
    "AppsCreateServicesError": [
      "ArgumentError"
    ],
    "AppsDeleteError": [
      "ArgumentError"
    ],
    "AppsUpdateError": [
      "ArgumentError",
      "TemplateLineError"
    ],
    "AppsUpdateSettingsError": [
      "ArgumentError"
    ],
    "BuildArgsCreateError": [
      "ArgumentError"
    ],
    "BuildArgsDeleteError": [
      "ArgumentError"
    ],
    "BuildArgsUpdateError": [
      "ArgumentError"
    ],
    "BuildsCancelError": [
      "OperationError"
    ],
    "BuildsCreateError": [
      "ArgumentError"
    ],
    "BuildsRebuildError": [
      "OperationError"
    ],
    "ClaimerUnion": [
      "Environment",
      "User"
    ],
    "CloudIntegrationsCreateError": [
      "ArgumentError"
    ],
    "CloudIntegrationsDeleteError": [
      "ArgumentError"
    ],
    "CloudIntegrationsUpdateError": [
      "ArgumentError"
    ],
    "CloudResourceInterface": [
      "EcsCloudResource",
      "InstantDatasetCloudResource"
    ],
    "CloudSecretsAwsCreateError": [
      "OperationError"
    ],
    "CloudSecretsAwsCreateSsmError": [
      "OperationError"
    ],
    "CloudSecretsDeleteError": [
      "OperationError"
    ],
    "ClustersCreateError": [
      "ArgumentError"
    ],
    "ClustersDeleteError": [
      "ArgumentError"
    ],
    "ClustersGenerateKubeConfigDownloadUrlError": [
      "OperationError"
    ],
    "ClustersImportError": [
      "ArgumentError"
    ],
    "ClustersLaunchError": [
      "ArgumentError"
    ],
    "ClustersPatchNodeGroupsError": [
      "OperationError"
    ],
    "ClustersRenewCertificateError": [
      "OperationError"
    ],
    "ClustersSetDefaultError": [
      "OperationError"
    ],
    "ClustersToggleDatadogAgentError": [
      "OperationError"
    ],
    "ClustersUpgradeError": [
      "OperationError"
    ],
    "ClustersVerifyError": [
      "OperationError"
    ],
    "DatabaseID": [
      "AiConfiguration",
      "App",
      "ApplicationTemplate",
      "ApplicationTemplateEnvironmentVariables",
      "AuroraDatabase",
      "AuroraPool",
      "Build",
      "BuildArg",
      "CloudIntegration",
      "CloudProvider",
      "CloudSqlDatabase",
      "CloudSqlPool",
      "Cluster",
      "Customer",
      "CustomerSettings",
      "DatadogIntegration",
      "DeliveryInstallation",
      "DependencySchedule",
      "Domain",
      "EmailAddress",
      "Environment",
      "EnvironmentConfiguration",
      "EnvironmentConfigurationEnvironmentVariables",
      "EnvironmentDependencyGroup",
      "EnvironmentPipeline",
      "EnvironmentPipelineStage",
      "EnvironmentPreset",
      "EnvironmentPresetPool",
      "ExternalRegistry",
      "FileMount",
      "HuggingFaceIntegration",
      "Identity",
      "IdentityProvider",
      "Initiation",
      "Invite",
      "Log",
      "Namespace",
      "NodeGroup",
      "OpenAiIntegration",
      "PlanetScaleDatabase",
      "PlanetScaleIntegration",
      "PlanetScalePool",
      "Pod",
      "PoolItem",
      "ProjectMapping",
      "PullRequest",
      "RdsDatabase",
      "RdsPool",
      "ReleaseInstallation",
      "Repository",
      "RepositoryAnalysis",
      "Role",
      "Secret",
      "Service",
      "SlackIntegration",
      "SshKey",
      "Task",
      "TaskChain",
      "User",
      "VercelIntegration",
      "WandbIntegration",
      "Workspace"
    ],
    "DatabaseInterface": [
      "AuroraDatabase",
      "CloudSqlDatabase",
      "PlanetScaleDatabase",
      "RdsDatabase"
    ],
    "DatabasePoolConfigurationInterface": [
      "AuroraPoolConfiguration",
      "CloudSqlPoolConfiguration",
      "PlanetScalePoolConfiguration",
      "RdsPoolConfiguration"
    ],
    "DatabasePoolInterface": [
      "AuroraPool",
      "CloudSqlPool",
      "PlanetScalePool",
      "RdsPool"
    ],
    "DatabasePoolSettingsProviderUnion": [
      "Cluster",
      "StandaloneDatasetConfiguration"
    ],
    "DatabaseSettingsProviderInterface": [
      "Cluster",
      "StandaloneDatasetConfiguration"
    ],
    "DatasetsCheckinError": [
      "OperationError"
    ],
    "DatasetsCheckoutError": [
      "OperationError"
    ],
    "DatasetsCreateError": [
      "ArgumentError"
    ],
    "DatasetsCreateStandaloneError": [
      "ArgumentError"
    ],
    "DatasetsRefreshError": [
      "OperationError"
    ],
    "DatasetsUpdateError": [
      "ArgumentError"
    ],
    "DependencySchedulesCreateError": [
      "ArgumentError"
    ],
    "DependencySchedulesDeleteError": [
      "OperationError"
    ],
    "DependencySchedulesUpdateError": [
      "ArgumentError"
    ],
    "DirectUploadsCreateError": [
      "OperationError"
    ],
    "DocumentInterface": [
      "ApplicationTemplate",
      "ApplicationTemplateEnvironmentVariables",
      "EnvironmentConfiguration",
      "EnvironmentConfigurationEnvironmentVariables"
    ],
    "DomainsCreateError": [
      "ArgumentError"
    ],
    "DomainsDeleteError": [
      "OperationError"
    ],
    "DomainsVerifyError": [
      "OperationError"
    ],
    "EchoError": [
      "OperationError"
    ],
    "EnvironmentConfigurationsCreateEnvironmentVariablesError": [
      "OperationError",
      "TemplateLineError"
    ],
    "EnvironmentConfigurationsCreateError": [
      "OperationError",
      "TemplateLineError"
    ],
    "EnvironmentDocumentUnion": [
      "EnvironmentConfiguration",
      "EnvironmentConfigurationEnvironmentVariables"
    ],
    "EnvironmentPipelinesAbortError": [
      "OperationError"
    ],
    "EnvironmentPresetsCreateError": [
      "ArgumentError"
    ],
    "EnvironmentPresetsDeleteEnvironmentVariableError": [
      "OperationError"
    ],
    "EnvironmentPresetsUpdateError": [
      "ArgumentError"
    ],
    "EnvironmentPresetsUpsertEnvironmentVariableError": [
      "OperationError"
    ],
    "EnvironmentsCreateError": [
      "ArgumentError",
      "EnvironmentsNoClusterAvailableError"
    ],
    "EnvironmentsCustomWorkflowError": [
      "OperationError",
      "PipelinesDeployCreationError",
      "PipelinesEnvironmentNotRunningError",
      "PipelinesEnvironmentNotSetupError",
      "PipelinesNoBuildFoundError"
    ],
    "EnvironmentsExtendError": [
      "OperationError"
    ],
    "EnvironmentsPatchError": [
      "OperationError",
      "PipelinesDeployCreationError",
      "PipelinesEnvironmentNotRunningError",
      "PipelinesNoBuildFoundError"
    ],
    "EnvironmentsPauseError": [
      "OperationError"
    ],
    "EnvironmentsResumeError": [
      "OperationError"
    ],
    "EnvironmentsSetupError": [
      "OperationError",
      "PipelinesDeployCreationError",
      "PipelinesEnvironmentNotRunningError",
      "PipelinesNoBuildFoundError"
    ],
    "EnvironmentsTeardownError": [
      "OperationError",
      "PipelinesDeployCreationError"
    ],
    "EnvironmentsUpdateAppImportsError": [
      "OperationError"
    ],
    "ExternalRegistriesCreateError": [
      "ArgumentError"
    ],
    "ExternalRegistriesDeleteError": [
      "OperationError"
    ],
    "ExternalRegistriesUpdateError": [
      "ArgumentError"
    ],
    "FileMountsCreateError": [
      "ArgumentError"
    ],
    "FileMountsDeleteError": [
      "ArgumentError"
    ],
    "IdentitiesDeleteError": [
      "OperationError"
    ],
    "IdentityProvidersCreateError": [
      "ArgumentError"
    ],
    "InstalledIntegrationInterface": [
      "DatadogIntegration",
      "HuggingFaceIntegration",
      "OpenAiIntegration",
      "PlanetScaleIntegration",
      "SlackIntegration",
      "VercelIntegration",
      "WandbIntegration"
    ],
    "IntegrationInterface": [
      "AvailableIntegration",
      "DatadogIntegration",
      "HuggingFaceIntegration",
      "OpenAiIntegration",
      "PlanetScaleIntegration",
      "SlackIntegration",
      "VercelIntegration",
      "WandbIntegration"
    ],
    "IntegrationOwnerUnion": [
      "Workspace"
    ],
    "IntegrationsCreateDatadogError": [
      "ArgumentError"
    ],
    "IntegrationsCreateHuggingFaceError": [
      "ArgumentError"
    ],
    "IntegrationsCreateOpenAiError": [
      "ArgumentError"
    ],
    "IntegrationsCreatePlanetScaleError": [
      "ArgumentError"
    ],
    "IntegrationsCreateProjectMappingError": [
      "ArgumentError"
    ],
    "IntegrationsCreateVercelError": [
      "ArgumentError"
    ],
    "IntegrationsCreateWandbError": [
      "ArgumentError"
    ],
    "IntegrationsDeleteError": [
      "ArgumentError"
    ],
    "IntegrationsDeleteProjectMappingError": [
      "OperationError"
    ],
    "IntegrationsUpdateDatadogError": [
      "ArgumentError"
    ],
    "IntegrationsUpdateHuggingFaceError": [
      "ArgumentError"
    ],
    "IntegrationsUpdateOpenAiError": [
      "ArgumentError"
    ],
    "IntegrationsUpdatePlanetScaleError": [
      "ArgumentError"
    ],
    "IntegrationsUpdateProjectMappingError": [
      "ArgumentError"
    ],
    "IntegrationsUpdateSlackError": [
      "ArgumentError"
    ],
    "IntegrationsUpdateVercelError": [
      "ArgumentError"
    ],
    "IntegrationsUpdateWandbError": [
      "ArgumentError"
    ],
    "InvitesAcceptError": [
      "ArgumentError"
    ],
    "InvitesCreateError": [
      "ArgumentError"
    ],
    "InvitesDeleteError": [
      "OperationError"
    ],
    "InvitesResendError": [
      "OperationError"
    ],
    "K8sResourcesStreamLogsError": [
      "OperationError"
    ],
    "LogsInterface": [
      "Build",
      "Cluster",
      "DeliveryInstallation",
      "PoolItem",
      "Task"
    ],
    "Node": [
      "AiConfiguration",
      "App",
      "ApplicationTemplate",
      "ApplicationTemplateEnvironmentVariables",
      "AuroraDatabase",
      "AuroraPool",
      "AvailableIntegration",
      "Build",
      "BuildArg",
      "CloudIntegration",
      "CloudProvider",
      "CloudSqlDatabase",
      "CloudSqlPool",
      "Cluster",
      "Customer",
      "CustomerSettings",
      "DatadogIntegration",
      "DeliveryInstallation",
      "DependencySchedule",
      "Domain",
      "EmailAddress",
      "Environment",
      "EnvironmentConfiguration",
      "EnvironmentConfigurationEnvironmentVariables",
      "EnvironmentDependencyGroup",
      "EnvironmentPipeline",
      "EnvironmentPipelineStage",
      "EnvironmentPreset",
      "EnvironmentPresetPool",
      "ExternalRegistry",
      "FileMount",
      "HuggingFaceIntegration",
      "Identity",
      "IdentityProvider",
      "Initiation",
      "Invite",
      "Log",
      "Namespace",
      "NodeGroup",
      "OpenAiIntegration",
      "PlanetScaleDatabase",
      "PlanetScaleIntegration",
      "PlanetScalePool",
      "Pod",
      "PoolItem",
      "ProjectMapping",
      "PullRequest",
      "RdsDatabase",
      "RdsPool",
      "ReleaseInstallation",
      "Repository",
      "RepositoryAnalysis",
      "Role",
      "Secret",
      "Service",
      "SlackIntegration",
      "SshKey",
      "Task",
      "TaskChain",
      "User",
      "VercelIntegration",
      "VercelProject",
      "WandbIntegration",
      "Workspace"
    ],
    "NodeGroupsCreateError": [
      "ArgumentError"
    ],
    "NodeGroupsDeleteError": [
      "OperationError"
    ],
    "NodeGroupsUpdateError": [
      "ArgumentError"
    ],
    "OrganizationsFindOrCreateError": [
      "ArgumentError"
    ],
    "PaymentsGenerateUrlError": [
      "ArgumentError"
    ],
    "PaymentsValidateError": [
      "ArgumentError"
    ],
    "PipelineInterface": [
      "EnvironmentPipeline"
    ],
    "PoolInterface": [
      "AuroraPool",
      "CloudSqlPool",
      "EnvironmentPresetPool",
      "PlanetScalePool",
      "RdsPool"
    ],
    "PoolableUnion": [
      "AuroraDatabase",
      "CloudSqlDatabase",
      "EnvironmentPreset",
      "PlanetScaleDatabase",
      "RdsDatabase"
    ],
    "PoolsDeleteError": [
      "OperationError"
    ],
    "PrimaryLinksCreateError": [
      "ArgumentError"
    ],
    "PrimaryLinksDeleteError": [
      "ArgumentError"
    ],
    "ProjectInterface": [
      "VercelProject"
    ],
    "ReleaseInstallationsRegisterError": [
      "ArgumentError"
    ],
    "SecretsCreateError": [
      "ArgumentError"
    ],
    "SecretsDeleteError": [
      "OperationError"
    ],
    "SecretsUpdateError": [
      "ArgumentError"
    ],
    "ServicesUpdateError": [
      "ArgumentError"
    ],
    "SshKeysCreateError": [
      "ArgumentError"
    ],
    "SshKeysDeleteError": [
      "OperationError"
    ],
    "UserError": [
      "ArgumentError",
      "EnvironmentsNoClusterAvailableError",
      "OperationError",
      "PipelinesDeployCreationError",
      "PipelinesEnvironmentNotRunningError",
      "PipelinesEnvironmentNotSetupError",
      "PipelinesNoBuildFoundError",
      "TemplateLineError"
    ],
    "UsersGenerateAuthenticationTokenError": [
      "OperationError"
    ],
    "UsersLogoutError": [
      "OperationError"
    ],
    "UsersUpdateProfileError": [
      "ArgumentError"
    ],
    "UsersUpdateSettingsError": [
      "ArgumentError"
    ],
    "WorkspacesApplyPromoCodeError": [
      "ArgumentError"
    ],
    "WorkspacesAttachIdentityError": [
      "OperationError"
    ],
    "WorkspacesCreateError": [
      "ArgumentError"
    ],
    "WorkspacesDeleteMemberError": [
      "OperationError"
    ],
    "WorkspacesDetachIdentityError": [
      "OperationError"
    ],
    "WorkspacesUpdateError": [
      "ArgumentError"
    ],
    "WorkspacesUpdateSettingsError": [
      "ArgumentError"
    ],
    "WorkspacesUpdateUserRoleError": [
      "OperationError"
    ]
  }
};
      export default result;
    