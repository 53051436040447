const typography = {
  fonts: {
    body: "Satoshi, Arial, Helvetica, sans-serif",
    heading: "Satoshi, Arial, Helvetica, sans-serif",
    mono: "'Jetbrains Mono', monospace",
  },
  fontSizes: {
    "fs.50": "11px",
    "fs.75": "12px",
    "fs.100": "13px",
    "fs.200": "14px",
    "fs.300": "15px",
    "fs.400": "16px",
    "fs.500": "18px",
    "fs.600": "24px",
    "fs.700": "28px",
    "fs.800": "32px",
    "fs.900": "40px",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.4,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },
  letterSpacings: {
    "ls.50": "-0.01em",
    "ls.100": "0.01em",
    "ls.200": "0.02em",
    "ls.250": "0.025em",
    "ls.300": "0.04em",
  },
};

export default typography;
