import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    const definition = getMainDefinition(operation.query);
    const mutation = definition.kind === "OperationDefinition" && definition.operation === "mutation";

    return !mutation && !!error && count < 3;
  },
});

export default retryLink;
