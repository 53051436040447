import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const solid = defineStyle(props => {
  const hoverBg = mode(`${props.colorScheme}.700`, `${props.colorScheme}.400`)(props);
  const disabledBg = mode("background.darkest", `blackAlpha.200`)(props);
  return {
    _disabled: {
      bg: disabledBg,
      color: "text.lighter",
      opacity: 1,
    },
    _hover: {
      bg: hoverBg,
      _disabled: {
        bg: disabledBg,
      },
    },
    '&[data-testid="hover"]': {
      bg: hoverBg,
    },
  };
});

const outline = defineStyle({
  bg: "background.base",
  borderColor: "border.base",
  _hover: {
    bg: "background.darker",
  },
});

const outlineShadow = defineStyle({
  bg: "background.base",
  border: "1px solid",
  borderColor: "border.base",
  color: "text.base",
  filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08))",
  _hover: {
    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))",
  },
});

const ghost = defineStyle({
  _hover: {
    cursor: "pointer",
  },
});

const grayGhost = defineStyle({
  color: "text.base",
  _hover: {
    color: "text.primary",
    bg: "background.darker",
  },
  _active: {
    bg: "background.darkest",
  },
});

const highlight = defineStyle(props => {
  const { colorScheme: c } = props;
  return {
    borderWidth: 0,
    _hover: {
      color: `${c}.500`,
    },
  };
});

const lowlight = defineStyle(props => {
  const { colorScheme: c } = props;
  return {
    borderWidth: 0,
    color: `${c}.500`,
    _hover: {
      color: `${c}.700`,
    },
  };
});

const primaryTransparent = defineStyle({
  color: "text.primary",
  backgroundColor: "background.darker",
  border: "1px solid",
  borderColor: "border.base",
  _hover: {
    color: "text.primary",
    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))",
  },
  _active: {
    bg: "background.darkest",
  },
});

const refresh = defineStyle({
  color: "text.secondary",
  background: "background.darker",
  _hover: {
    background: "border.base",
  },
  _active: {
    background: "border.base",
  },
});

export default defineStyleConfig({
  baseStyle: {
    letterSpacing: "ls.200",
    fontWeight: "bold",
    fontFeatureSettings: "inherit",
    borderRadius: "lg",
    _hover: {
      textDecoration: "none",
    },
    _active: {
      transform: "scale(.95)",
      _disabled: {
        transform: "none",
      },
    },
  },
  sizes: {
    md: {
      fontSize: "fs.100",
      px: 7,
      py: 6,
    },
    action: {
      px: 4,
      py: 3,
    },
    tiny: {
      h: "18px",
      w: "18px",
      minW: 0,
    },
  },
  variants: {
    solid,
    outline,
    outlineShadow,
    ghost,
    grayGhost,
    highlight,
    lowlight,
    primaryTransparent,
    refresh,
  },
  defaultProps: {},
});
