/* eslint-disable react/jsx-filename-extension */
import { useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useRouter } from "next/router";

import { getFragmentData, graphql } from "@/__generated__";
import { Maybe } from "@/__generated__/graphql";

const QTracking = graphql(`
  query QTracking {
    workspaces {
      ...Tracking_WorkspaceCxn
    }
    currentUser {
      ...Tracking_User
    }
    isImpersonating
  }
`);

const Tracking_User = graphql(`
  fragment Tracking_User on User {
    id
    ...Datadog_User
    ...Freshpaint_User
    ...Rollbar_User
  }
`);

const Tracking_WorkspaceCxn = graphql(`
  fragment Tracking_WorkspaceCxn on WorkspaceConnection {
    ...Freshpaint_WorkspaceCxn
  }
`);

interface ChildrenProps {
  currentUser?: Maybe<ResultOf<typeof Tracking_User>>;
  workspaces?: Maybe<ResultOf<typeof Tracking_WorkspaceCxn>>;
  isImpersonating?: boolean;
}

interface Props {
  children: (props: ChildrenProps) => JSX.Element;
}

function Tracking({ children }: Props) {
  const { asPath } = useRouter();
  const { data } = useQuery(QTracking, {
    skip: !asPath.includes("workspaces"),
  });

  return children({
    currentUser: getFragmentData(Tracking_User, data?.currentUser),
    workspaces: getFragmentData(Tracking_WorkspaceCxn, data?.workspaces),
  });
}

export default Tracking;
