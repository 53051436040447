/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-multi-comp */

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useRouter } from "next/router";
import { useLocalStorage } from "usehooks-ts";

import { getFragmentData, graphql } from "@/__generated__";
import { PlanEnum, Product } from "@/__generated__/graphql";
import useReadySearchParams from "@/hooks/useReadySearchParams";
import createProvider from "@/providerFactories/createProvider";

const QWorkspaceProvider = graphql(`
  query QWorkspaceProvider {
    workspaces {
      nodes {
        ...WorkspaceProvider_Workspace
      }
    }
  }
`);

const WorkspaceProvider_Workspace = graphql(`
  fragment WorkspaceProvider_Workspace on Workspace {
    id
    avatarUrl
    databaseId
    name
    unhashedDatabaseId
    products
    plan
    expiringPlan
    expiredPlan
    featureFlags {
      key
      enabled
    }
    ...WorkspaceDetails_Workspace
    ...SelectWorkspaceItem_Workspace
  }
`);

type WorkspaceProviderType = {
  currentWorkspace?: ResultOf<typeof WorkspaceProvider_Workspace> | null;
  isSandbox?: boolean;
  product?: Product;
  workspaceId: string;
  workspaces?: Readonly<ResultOf<typeof WorkspaceProvider_Workspace>[]>;
  setWorkspaceId: Dispatch<SetStateAction<string | undefined>> | (() => void);
};

export const initialData: WorkspaceProviderType = {
  workspaceId: "",
  workspaces: [],
  setWorkspaceId: () => {
    return;
  },
};

const useWorkspaceProviderHook = () => {
  const router = useRouter();
  const { workspaceId } = useReadySearchParams();
  const [storageWorkspaceId, setStorageWorkspaceId] = useLocalStorage<string | undefined>(
    "workspaceId",
    undefined
  );
  const [workspace, setWorkspace] = useState<ResultOf<typeof WorkspaceProvider_Workspace>>();

  const currentProduct = useMemo(() => {
    if (router.asPath.includes("instantdatasets")) {
      return Product.StandaloneDatasets;
    } else if (router.asPath.includes("/ai/")) {
      return Product.ReleaseAi;
    } else if (workspace?.products) {
      return workspace?.products.includes(Product.Release) ? Product.Release : Product.StandaloneDatasets;
    } else {
      return Product.Release;
    }
  }, [router, workspace]);

  const { data, called, loading } = useQuery(QWorkspaceProvider);

  const workspaces = useMemo(
    () => getFragmentData(WorkspaceProvider_Workspace, data?.workspaces?.nodes),
    [data?.workspaces?.nodes]
  );

  const onWorkspaceChange = useCallback(() => {
    if (workspaces) {
      const wId = workspaceId || storageWorkspaceId;
      const currentWorkspace =
        wId &&
        workspaces?.find(workspace => workspace.databaseId === wId || workspace.unhashedDatabaseId === wId);

      if (!currentWorkspace) {
        // Check if a Workspace is set into local storage, if they do, retain that otherwise set a default
        if (!storageWorkspaceId) {
          setWorkspace(workspaces[0]);
        }
      } else {
        setWorkspace(currentWorkspace);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWorkspace, workspaceId, workspaces, storageWorkspaceId]);

  const { databaseId, plan } = workspace || {};
  const isSandbox = useMemo(() => plan === PlanEnum.Sandbox, [plan]);

  useEffect(() => {
    if (router.isReady && called && !loading) onWorkspaceChange();
  }, [router.isReady, called, loading, onWorkspaceChange]);

  return {
    isSandbox,
    workspaceId: databaseId || workspaceId || "",
    workspaces: workspaces || [],
    currentWorkspace: workspace,
    product: currentProduct,
    setWorkspaceId: setStorageWorkspaceId,
  };
};

export const [useWorkspace, WorkspaceProvider, WorkspaceConsumer] = createProvider<
  WorkspaceProviderType,
  PropsWithChildren<unknown>
>(initialData, useWorkspaceProviderHook);
