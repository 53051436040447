export const wsLink = (ssr: boolean) => {
  if (ssr) {
    return null;
  } else {
    // Only import the package on the client-side
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createConsumer } = require("@rails/actioncable");
    let url = process.env.NEXT_PUBLIC_BACKEND_BASE_URL
      ? `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/cable`
      : "http://localhost:3000/cable";
    url = url.replace("https://", "wss://").replace("http://", "ws://");
    return createConsumer(url);
  }
};
