enum localStorageKeys {
  SHOW_ALERT_CREATEDEMOAPP_CREATEAPP = "ALERTCREATEDEMOAPPCREATEAPP",
  CREATE_APP_CURRENT_STEP = "CREATE_APP_CURRENT_STEP",
  LAST_USED_WORKSPACE_ID = "LAST_USED_WORKSPACE_ID",
  VERCEL_INTEGRATION_ID = "VERCEL_INTEGRATION_ID",
  TERMINALS_PANEL = "TERMINALS_PANEL",
  TERMINALS_POPOUT = "TERMINALS_POPOUT",
  COLOR_MODE_SETTING = "COLOR_MODE_SETTING",
  TIME_WARNING_SEEN = "TIME_WARNING_SEEN",
  EXPIRED_WARNING_SEEN = "EXPIRED_WARNING_SEEN",
  SANDBOX_INFO_SEEN = "SANDBOX_INFO_SEEN",
}

export default localStorageKeys;
