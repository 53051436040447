import Accordion from "./accordion";
import Avatar from "./avatar";
import Badge from "./badge";
import Breadcrumb from "./breadcrumb";
import Button from "./button";
import Card from "./card";
import Code from "./code";
import Divider from "./divider";
import Drawer from "./drawer";
import Form from "./form";
import FormLabel from "./formLabel";
import Heading from "./heading";
import Input from "./input";
import Link from "./link";
import List from "./list";
import Menu from "./menu";
import Modal from "./modal";
import NumberInput from "./numberInput";
import Tabs from "./tabs";
import Text from "./text";
import Tooltip from "./tooltip";

export const components = {
  Accordion,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Code,
  Divider,
  Drawer,
  Form,
  FormLabel,
  Heading,
  Input,
  Link,
  List,
  Menu,
  Modal,
  NumberInput,
  Tabs,
  Text,
  Tooltip,
};
