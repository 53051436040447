import { useEffect } from "react";

import { Center } from "@chakra-ui/react";

/* contentLoaded is a function which returns a boolean but also uses a Typescript type predicate
   See https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
   By using the Typescript utility type NonNullable, we assert that when contentLoaded returns
   true, we know that object is not null or undefined and when it returns false, object is the opposite.

   This allows us to do

     if (contentLoaded(called, loading, object) {
       someOtherFunction(object.property)
     }

   and know that object doesn't need to be null checked (object?.property).
 */
export function contentLoaded(
  called: boolean,
  loading: boolean,
  object: unknown
): object is NonNullable<unknown> {
  /* The logic goes as follows
     - If the object is present, then we'll return true as we have the required data. This data
       could come from an initial request on a first page load or it could be supplied from the Apollo
       cache on subsequent requests.
     - If there is no object then we check to see if the GraphQL query has been called. If it hasn't been
       called we know the data is not present. If it has been called and is still loading we'll want to
       render the loader.
 */
  if (object) return true;
  return called && !loading && object !== null && object !== undefined;
}

function ContentLoader({ todo }: { todo?: boolean }) {
  useEffect(() => {
    async function getLoader() {
      const { orbit } = await import("ldrs");
      orbit.register();
    }
    getLoader();
  }, []);
  return (
    <Center h="full">
      <l-orbit color={`var(--chakra-colors-${todo ? "niagara" : "plum"}-600)`} size={40} />
    </Center>
  );
}

export default ContentLoader;
