const borders = {
  radii: {
    none: "0",
    sm: "0.125rem",
    base: "0.25rem", // 4px
    md: "0.375rem", // 6px
    lg: "0.5rem", // 8px
    xl: "0.75rem",
    "2xl": "1rem", // 16px
    "3xl": "1.5rem",
    full: "9999px",
  },
};

export default borders;
