import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import inputConfig from "./input";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);
const baseStyle = definePartsStyle({
  field: {
    pr: 12,
  },
  stepper: {
    _active: {
      background: "none",
    },
    border: "none",
    px: 2,
    fontSize: "lg !important",
    color: "plum.500",
  },
  stepperGroup: {
    py: 1,
    w: 12,
  },
});

export default defineMultiStyleConfig({ ...inputConfig, baseStyle });
