import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    helperText: {
      color: "text.lighter",
      fontSize: "fs.50",
      fontWeight: "normal",
      lineHeight: "18px",
    },
  },
});
