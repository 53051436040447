/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-filename-extension */

import { ReactNode, useState } from "react";

import { ActionItem } from "@/components/Actions";
import createProvider from "@/providerFactories/createProvider";

type LayoutSlotsProviderType = {
  actions?: ActionItem[];
  setActions: (actions: ActionItem[] | undefined) => void;
  badges?: ReactNode;
  setBadges: (badges: ReactNode) => void;
};

const initialValues: LayoutSlotsProviderType = {
  actions: [],
  setActions: () => null,
  badges: "",
  setBadges: () => null,
};

const useLayoutSlotsProviderHook = () => {
  const [actions, setActions] = useState<ActionItem[]>();
  const [badges, setBadges] = useState<ReactNode>();

  return {
    actions,
    setActions,
    badges,
    setBadges,
  };
};

export const [useLayoutSlots, LayoutSlotsProvider, LayoutSlotsConsumer] = createProvider<
  LayoutSlotsProviderType,
  never
>(initialValues, useLayoutSlotsProviderHook);
