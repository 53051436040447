/** wrapWith
 *
 * Helper function to nest JSX wrappers.
 *
 * Examples:
 *
 * const CustomHooksProvider = wrapWith(ProviderA, ProviderB, ProviderC);
 * const MasterLayout = wrapWith(BaseLayout, AuthLayout, SpecificPageLayout)
 *
 * */

export const wrapWith =
  (...wrappers) =>
  ({ children }) =>
    // eslint-disable-next-line react/jsx-filename-extension
    wrappers.reduceRight((rendered, Component) => <Component>{rendered}</Component>, children);
