import { ReactElement, ReactNode } from "react";

import "@/dayjsConfig";
import "@/styles/common.css";
import "@/styles/fonts/jet-brains-mono.css";
import "@/styles/fonts/satoshi.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/space-mono";

import { ApolloClient, ApolloProvider } from "@apollo/client";
import { NextPage } from "next";
import { NextRouter, useRouter } from "next/router";
import { Toaster } from "sonner";
import { useIsClient } from "usehooks-ts";

import { useApollo } from "@/apollo/apolloClient";
import { Chakra } from "@/Chakra";
import ContentLoader from "@/components/shared/ContentLoader";
import BaseLayout from "@/layouts/BaseLayout";
import { LayoutDataProviderType } from "@/providers/useLayoutData";
import colors from "@/theme/foundations/colors";

import type { AppProps } from "next/app";

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("@/msw");
}

interface Props {
  Component: React.FC;
  apollo: ApolloClient<unknown>;
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, layoutData?: LayoutDataProviderType, router?: NextRouter) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

/* Note: This is the root of the application. We're going to use this to add Providers and components
         that are needed to render the application.
         There is a redirect in the middleware that takes the root path "/" and sends it to
         pages/home/index.page.tsx. That page will house a bunch of redirect logic to figure out
         where to send the current user to.
 */
function App({ Component, pageProps }: Props & AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? (page => page);
  const apolloClient = useApollo(pageProps);
  const isClient = useIsClient();
  const router = useRouter();

  return (
    <ApolloProvider client={apolloClient}>
      <Toaster
        closeButton
        richColors
        position="bottom-center"
        toastOptions={{
          actionButtonStyle: { background: colors.plum["500"] },
          duration: 5000,
        }}
      />
      <Chakra>
        <BaseLayout>
          {layoutData =>
            router.isReady && isClient ? (
              getLayout(<Component {...pageProps} />, layoutData, router)
            ) : (
              <ContentLoader />
            )
          }
        </BaseLayout>
      </Chakra>
    </ApolloProvider>
  );
}
export default App;
