import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = {
  fontWeight: "semibold",
};

const brandState = defineStyle({
  textTransform: "capitalize",
  py: "6px",
  px: "12px",
  background: "background.darker",
  color: "text.darker",
  borderRadius: "6px",
});

const transparent = defineStyle({
  textTransform: "none",
  border: "1px",
  borderColor: "border.lighter",
  py: "6px",
  px: "12px",
  color: "text.lighter",
  borderRadius: "6px",
});

const compact = defineStyle({
  py: "3px",
  px: "6px",
  background: "gray.50",
  color: "text.darker",
  borderRadius: "6px",
});

export default defineStyleConfig({
  baseStyle,
  variants: { brandState, transparent, compact },
  defaultProps: { variant: "brandState" },
});
