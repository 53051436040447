import React, { PropsWithChildren } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { Provider as RollbarProvider } from "@rollbar/react";

import { FragmentType, getFragmentData, graphql } from "@/__generated__";
import { Maybe } from "@/__generated__/graphql";

const Rollbar_User = graphql(`
  fragment Rollbar_User on User {
    databaseId
    username
    primaryEmailAddress {
      email
    }
  }
`);

function getEnvironment(environment?: string) {
  switch (environment) {
    case "local":
    case "dev":
      return null;
    case "staging":
      return "staging";
    case "prod":
      return "production";
    default:
      return "unknown";
  }
}

function Rollbar({
  children,
  ...props
}: PropsWithChildren<{
  currentUser?: Maybe<FragmentType<typeof Rollbar_User>>;
}>) {
  const currentUser = getFragmentData(Rollbar_User, props.currentUser);
  const { databaseId: userId, username, primaryEmailAddress } = currentUser || {};

  const datadogContext = datadogRum.getInternalContext();
  const env = getEnvironment(process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE);

  // https://docs.rollbar.com/docs/rollbarjs-configuration-reference
  const rollbarConfig = {
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_POST_CLIENT_ITEM,
    enabled: env !== null,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: env ?? "",
      // https://docs.rollbar.com/docs/code-context
      code_version: process.env.NEXT_PUBLIC_RELEASE_COMMIT_SHA || "",
      client: {
        javascript: {
          source_map_enabled: true, // true by default

          // -- Add this into your configuration ---
          code_version: process.env.NEXT_PUBLIC_RELEASE_COMMIT_SHA || "",
          // ---------------------------------------

          // Optionally have Rollbar guess which frames the error was
          // thrown from when the browser does not provide line
          // and column numbers.
          guess_uncaught_frames: true,
        },
      },
      ...(datadogContext && { datadog: datadogContext }),
      person: {
        id: userId ?? "",
        email: primaryEmailAddress?.email ?? "",
        username: username ?? "",
      },
      server: {
        branch: process.env.NEXT_PUBLIC_RELEASE_BRANCH_NAME || "",
        root: "/app/src/",
      },
    },
  };

  // https://docs.rollbar.com/docs/react
  return <RollbarProvider config={rollbarConfig}>{children}</RollbarProvider>;
}

export default Rollbar;
