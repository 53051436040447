/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-multi-comp */

import { PropsWithChildren, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";

import { getFragmentData, graphql } from "@/__generated__";
import { IntegrationTypeEnum, QLayoutDataProviderQueryVariables } from "@/__generated__/graphql";
import useReadySearchParams from "@/hooks/useReadySearchParams";
import createProvider from "@/providerFactories/createProvider";

export type LayoutDataProviderType = {
  workspaces: Readonly<ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["workspaceId"]>[]>;
  layoutAiConfiguration?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["aiConfigurationId"]>;
  layoutApplication?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["applicationId"]>;
  layoutBuild?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["buildId"]>;
  layoutCluster?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["clusterId"]>;
  layoutCloudIntegration?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["cloudIntegrationId"]>;
  layoutDatabase?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["databaseId"]>;
  layoutDataset?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["datasetId"]>;
  layoutDeploy?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["deployId"]>;
  layoutDeliveryInstallation?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["deliveryInstallationId"]>;
  layoutDomain?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["domainId"]>;
  layoutEnvironment?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["environmentId"]>;
  layoutEnvironmentInstance?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["instanceId"]>;
  layoutEnvironmentPreset?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["environmentPresetId"]>;
  layoutExternalRegistry?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["externalRegistryId"]>;
  layoutIntegration?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["integrationId"]>;
  layoutNamespace?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["namespaceId"]>;
  layoutNodeGroup?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["nodeGroupId"]>;
  layoutPreset?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["presetId"]>;
  layoutWorkspace?: ResultOf<(typeof LAYOUT_DATA_FRAGMENTS)["workspaceId"]>;
};

export const initialData: LayoutDataProviderType = {
  workspaces: [],
};

const QLayoutDataProvider = graphql(`
  query QLayoutDataProvider(
    $includeAiConfiguration: Boolean!
    $includeApplication: Boolean!
    $includeBuild: Boolean!
    $includeCloudIntegration: Boolean!
    $includeCluster: Boolean!
    $includeDatabase: Boolean!
    $includeDataset: Boolean!
    $includeDeliveryInstallation: Boolean!
    $includeDeploy: Boolean!
    $includeDomain: Boolean!
    $includeEnvironment: Boolean!
    $includeEnvironmentInstance: Boolean!
    $includeEnvironmentPreset: Boolean!
    $includeExternalRegistry: Boolean!
    $includeIntegration: Boolean!
    $includeNamespace: Boolean!
    $includeNodeGroup: Boolean!
    $includePreset: Boolean!
    $includeWorkspace: Boolean!
    $aiConfigurationId: ID!
    $applicationId: ID!
    $buildId: ID!
    $cloudIntegrationId: ID!
    $clusterId: ID!
    $databaseId: ID!
    $datasetId: ID!
    $deliveryInstallationId: ID!
    $deployId: ID!
    $domainId: ID!
    $environmentId: ID!
    $environmentInstanceId: ID!
    $environmentPresetId: ID!
    $externalRegistryId: ID!
    $integrationType: IntegrationTypeEnum!
    $namespaceId: ID!
    $nodeGroupId: ID!
    $presetId: ID!
    $workspaceId: ID!
  ) {
    workspaces {
      nodes {
        ...LayoutDataProvider_Workspace
      }
    }
    workspace(id: $workspaceId) @include(if: $includeWorkspace) {
      ...LayoutDataProvider_Workspace
      aiConfiguration(id: $aiConfigurationId) @include(if: $includeAiConfiguration) {
        ...LayoutDataProvider_AiConfiguration
      }
      app(id: $applicationId) @include(if: $includeApplication) {
        ...LayoutDataProvider_Application
        build(id: $buildId) @include(if: $includeBuild) {
          ...LayoutDataProvider_Build
        }
        deploy: environmentPipeline(id: $deployId) @include(if: $includeDeploy) {
          ...LayoutDataProvider_Deploy
        }
        environment(id: $environmentId) @include(if: $includeEnvironment) {
          ...LayoutDataProvider_Environment
          pod(id: $environmentInstanceId) @include(if: $includeEnvironmentInstance) {
            ...LayoutDataProvider_EnvironmentInstance
          }
          namespace: environmentNamespace(id: $namespaceId) @include(if: $includeNamespace) {
            ...LayoutDataProvider_Namespace
          }
        }
      }
      cluster(id: $clusterId) @include(if: $includeCluster) {
        ...LayoutDataProvider_Cluster
        nodeGroup(id: $nodeGroupId) @include(if: $includeNodeGroup) {
          ...LayoutDataProvider_NodeGroup
        }
      }
      cloudIntegration(id: $cloudIntegrationId) @include(if: $includeCloudIntegration) {
        ...LayoutDataProvider_CloudIntegration
      }
      domain(id: $domainId) @include(if: $includeDomain) {
        ...LayoutDataProvider_Domain
      }
      databasePool(id: $datasetId) @include(if: $includeDataset) {
        ...LayoutDataProvider_Dataset
        item(id: $databaseId) @include(if: $includeDatabase) {
          ...LayoutDataProvider_Database
        }
      }
      deliveryInstallation(id: $deliveryInstallationId) @include(if: $includeDeliveryInstallation) {
        ...LayoutDataProvider_DeliveryInstallation
      }
      environmentPresetPool(id: $environmentPresetId) @include(if: $includeEnvironmentPreset) {
        ...LayoutDataProvider_EnvironmentPreset
        item(id: $presetId) @include(if: $includePreset) {
          ...LayoutDataProvider_Preset
        }
      }
      externalRegistry(id: $externalRegistryId) @include(if: $includeExternalRegistry) {
        ...LayoutDataProvider_ExternalRegistry
      }
      availableIntegration(integrationType: $integrationType) @include(if: $includeIntegration) {
        ...LayoutDataProvider_Integration
      }
      integration(integrationType: $integrationType) @include(if: $includeIntegration) {
        ...LayoutDataProvider_Integration
      }
    }
  }
`);

const integrationFragment = graphql(`
  fragment LayoutDataProvider_Integration on IntegrationInterface {
    id
    name
  }
`);

export const LAYOUT_DATA_FRAGMENTS = {
  workspaceId: graphql(`
    fragment LayoutDataProvider_Workspace on Workspace {
      id
      databaseId
      name
      avatarUrl
      unhashedDatabaseId
      featureFlags {
        key
        enabled
      }
    }
  `),
  aiConfigurationId: graphql(`
    fragment LayoutDataProvider_AiConfiguration on AiConfiguration {
      id
      databaseId
      name
      unhashedDatabaseId
    }
  `),
  applicationId: graphql(`
    fragment LayoutDataProvider_Application on App {
      id
      databaseId
      executionType
      name
      unhashedDatabaseId
      featureFlags {
        key
        enabled
      }
      ...ApplicationActions_Application
    }
  `),
  environmentId: graphql(`
    fragment LayoutDataProvider_Environment on Environment {
      id
      databaseId
      name
      unhashedDatabaseId
      executionType
      deploymentStrategy
      ...EnvironmentStateBadge_Environment
      ...EnvironmentActions_Environment
      ...EnvironmentSubtitle_Environment
    }
  `),
  buildId: graphql(`
    fragment LayoutDataProvider_Build on Build {
      id
      databaseId
      state
      unhashedDatabaseId
      ...BuildActions_Build
    }
  `),
  clusterId: graphql(`
    fragment LayoutDataProvider_Cluster on Cluster {
      id
      databaseId
      name
      context
      state
      default
      unhashedDatabaseId
    }
  `),
  cloudIntegrationId: graphql(`
    fragment LayoutDataProvider_CloudIntegration on CloudIntegration {
      id
      databaseId
      name
      unhashedDatabaseId
      credentialsConfigured
    }
  `),
  domainId: graphql(`
    fragment LayoutDataProvider_Domain on Domain {
      id
      databaseId
      name
      verified
      unhashedDatabaseId
    }
  `),
  datasetId: graphql(`
    fragment LayoutDataProvider_Dataset on DatabasePoolInterface {
      id
      databaseId
      name
      state
      unhashedDatabaseId
      claimerType
    }
  `),
  databaseId: graphql(`
    fragment LayoutDataProvider_Database on PoolItem {
      id
      databaseId
      unhashedDatabaseId
      state
      poolable {
        ... on DatabaseInterface {
          id
          name
        }
      }
    }
  `),
  deployId: graphql(`
    fragment LayoutDataProvider_Deploy on EnvironmentPipeline {
      id
      databaseId
      unhashedDatabaseId
      name
      state
      stages {
        id
        databaseId
        state
        name
      }
    }
  `),
  environmentPresetId: graphql(`
    fragment LayoutDataProvider_EnvironmentPreset on EnvironmentPresetPool {
      id
      databaseId
      name
      state
      unhashedDatabaseId
    }
  `),
  externalRegistryId: graphql(`
    fragment LayoutDataProvider_ExternalRegistry on ExternalRegistry {
      id
      databaseId
      unhashedDatabaseId
      host
    }
  `),
  integrationSlug: integrationFragment,
  integrationId: integrationFragment,
  deliveryInstallationId: graphql(`
    fragment LayoutDataProvider_DeliveryInstallation on DeliveryInstallation {
      id
      databaseId
      unhashedDatabaseId
      name
      state
    }
  `),
  instanceId: graphql(`
    fragment LayoutDataProvider_EnvironmentInstance on Pod {
      id
      databaseId
      unhashedDatabaseId
      name
      currentStatus
    }
  `),
  namespaceId: graphql(`
    fragment LayoutDataProvider_Namespace on Namespace {
      id
      databaseId
      unhashedDatabaseId
      name
      color
      extendedColor
      secondaryColor
      state
      removalAt
    }
  `),
  nodeGroupId: graphql(`
    fragment LayoutDataProvider_NodeGroup on NodeGroup {
      id
      databaseId
      name
      unhashedDatabaseId
    }
  `),
  presetId: graphql(`
    fragment LayoutDataProvider_Preset on PoolItem {
      id
      databaseId
      unhashedDatabaseId
      state
      poolable {
        ... on EnvironmentPreset {
          id
          handle
        }
      }
    }
  `),
};

export const layoutDataVariables = (
  readySearchParams: Record<string, string> = {}
): QLayoutDataProviderQueryVariables => {
  const {
    workspaceId,
    aiConfigurationId,
    applicationId,
    buildId,
    clusterId,
    cloudIntegrationId,
    databaseId,
    datasetId,
    deliveryInstallationId,
    deployId,
    domainId,
    environmentId,
    environmentPresetId,
    externalRegistryId,
    instanceId,
    integrationSlug,
    namespaceId,
    nodeGroupId,
    presetId,
  } = readySearchParams;
  return {
    includeWorkspace: !!workspaceId,
    includeAiConfiguration: !!aiConfigurationId,
    includeApplication: !!applicationId,
    includeEnvironment: !!applicationId && !!environmentId,
    includeBuild: !!applicationId && !!buildId,
    includeCloudIntegration: !!cloudIntegrationId,
    includeCluster: !!clusterId,
    includeDomain: !!domainId,
    includeDatabase: !!databaseId,
    includeDataset: !!datasetId,
    includeDeliveryInstallation: !!deliveryInstallationId,
    includeDeploy: !!applicationId && !!deployId,
    includeEnvironmentInstance: !!instanceId,
    includeEnvironmentPreset: !!environmentPresetId,
    includeExternalRegistry: !!externalRegistryId,
    includeIntegration: !!integrationSlug,
    includeNamespace: !!namespaceId,
    includeNodeGroup: !!nodeGroupId,
    includePreset: !!presetId,
    workspaceId: workspaceId ?? "",
    aiConfigurationId: aiConfigurationId ?? "",
    applicationId: applicationId ?? "",
    environmentId: environmentId ?? "",
    buildId: buildId ?? "",
    clusterId: clusterId ?? "",
    cloudIntegrationId: cloudIntegrationId ?? "",
    databaseId: databaseId ?? "",
    datasetId: datasetId ?? "",
    deliveryInstallationId: deliveryInstallationId ?? "",
    deployId: deployId ?? "",
    domainId: domainId ?? "",
    environmentInstanceId: instanceId ?? "",
    environmentPresetId: environmentPresetId ?? "",
    externalRegistryId: externalRegistryId ?? "",
    integrationType: (integrationSlug?.toUpperCase() as IntegrationTypeEnum) ?? IntegrationTypeEnum.Slack,
    namespaceId: namespaceId ?? "",
    nodeGroupId: nodeGroupId ?? "",
    presetId: presetId ?? "",
  };
};

const useLayoutDataProviderHook = () => {
  const readySearchParams = useReadySearchParams();
  const [layoutAiConfiguration, setLayoutAiConfiguration] =
    useState<LayoutDataProviderType["layoutAiConfiguration"]>();
  const [layoutApplication, setLayoutApplication] = useState<LayoutDataProviderType["layoutApplication"]>();
  const [layoutBuild, setLayoutBuild] = useState<LayoutDataProviderType["layoutBuild"]>();
  const [layoutCluster, setLayoutCluster] = useState<LayoutDataProviderType["layoutCluster"]>();
  const [layoutCloudIntegration, setLayoutCloudIntegration] =
    useState<LayoutDataProviderType["layoutCloudIntegration"]>();
  const [layoutDomain, setLayoutDomain] = useState<LayoutDataProviderType["layoutDomain"]>();
  const [layoutDatabase, setLayoutDatabase] = useState<LayoutDataProviderType["layoutDatabase"]>();
  const [layoutDataset, setLayoutDataset] = useState<LayoutDataProviderType["layoutDataset"]>();
  const [layoutDeliveryInstallation, setLayoutDeliveryInstallation] =
    useState<LayoutDataProviderType["layoutDeliveryInstallation"]>();
  const [layoutDeploy, setLayoutDeploy] = useState<LayoutDataProviderType["layoutDeploy"]>();
  const [layoutEnvironment, setLayoutEnvironment] = useState<LayoutDataProviderType["layoutEnvironment"]>();
  const [layoutEnvironmentInstance, setLayoutEnvironmentInstance] =
    useState<LayoutDataProviderType["layoutEnvironmentInstance"]>();
  const [layoutEnvironmentPreset, setLayoutEnvironmentPreset] =
    useState<LayoutDataProviderType["layoutEnvironmentPreset"]>();
  const [layoutExternalRegistry, setLayoutExternalRegistry] =
    useState<LayoutDataProviderType["layoutExternalRegistry"]>();
  const [layoutIntegration, setLayoutIntegration] = useState<LayoutDataProviderType["layoutIntegration"]>();
  const [layoutNamespace, setLayoutNamespace] = useState<LayoutDataProviderType["layoutNamespace"]>();
  const [layoutNodeGroup, setLayoutNodeGroup] = useState<LayoutDataProviderType["layoutNodeGroup"]>();
  const [layoutPreset, setLayoutPreset] = useState<LayoutDataProviderType["layoutPreset"]>();
  const [layoutWorkspace, setLayoutWorkspace] = useState<LayoutDataProviderType["layoutWorkspace"]>();

  const { data, called, loading } = useQuery(QLayoutDataProvider, {
    variables: layoutDataVariables(readySearchParams),
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (called && !loading && data?.workspace) {
      setLayoutAiConfiguration(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["aiConfigurationId"], data.workspace.aiConfiguration) ??
          undefined
      );
      setLayoutApplication(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["applicationId"], data.workspace.app) ?? undefined
      );
      setLayoutBuild(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["buildId"], data.workspace.app?.build) ?? undefined
      );
      setLayoutCloudIntegration(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["cloudIntegrationId"], data.workspace.cloudIntegration) ??
          undefined
      );
      setLayoutCluster(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["clusterId"], data.workspace.cluster) ?? undefined
      );
      setLayoutDatabase(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["databaseId"], data.workspace.databasePool?.item) ?? undefined
      );
      setLayoutDataset(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["datasetId"], data.workspace.databasePool) ?? undefined
      );
      setLayoutDeliveryInstallation(
        getFragmentData(
          LAYOUT_DATA_FRAGMENTS["deliveryInstallationId"],
          data.workspace.deliveryInstallation
        ) ?? undefined
      );
      setLayoutDeploy(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["deployId"], data.workspace.app?.deploy) ?? undefined
      );
      setLayoutDomain(getFragmentData(LAYOUT_DATA_FRAGMENTS["domainId"], data.workspace.domain) ?? undefined);
      setLayoutEnvironment(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["environmentId"], data.workspace.app?.environment) ?? undefined
      );
      setLayoutEnvironmentInstance(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["instanceId"], data.workspace.app?.environment?.pod) ??
          undefined
      );
      setLayoutEnvironmentPreset(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["environmentPresetId"], data.workspace.environmentPresetPool) ??
          undefined
      );
      setLayoutExternalRegistry(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["externalRegistryId"], data.workspace.externalRegistry) ??
          undefined
      );
      setLayoutIntegration(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["integrationId"], data.workspace.integration) ??
          getFragmentData(LAYOUT_DATA_FRAGMENTS["integrationId"], data.workspace.availableIntegration) ??
          undefined
      );
      setLayoutNamespace(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["namespaceId"], data.workspace.app?.environment?.namespace) ??
          undefined
      );
      setLayoutNodeGroup(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["nodeGroupId"], data.workspace.cluster?.nodeGroup) ?? undefined
      );
      setLayoutPreset(
        getFragmentData(LAYOUT_DATA_FRAGMENTS["presetId"], data.workspace.environmentPresetPool?.item) ??
          undefined
      );
      setLayoutWorkspace(getFragmentData(LAYOUT_DATA_FRAGMENTS["workspaceId"], data.workspace));
    }
  }, [called, loading, data]);

  return {
    workspaces: getFragmentData(LAYOUT_DATA_FRAGMENTS["workspaceId"], data?.workspaces.nodes) || [],
    layoutWorkspace,
    layoutAiConfiguration,
    layoutApplication,
    layoutEnvironment,
    layoutBuild,
    layoutCluster,
    layoutCloudIntegration,
    layoutDomain,
    layoutDatabase,
    layoutDataset,
    layoutDeliveryInstallation,
    layoutDeploy,
    layoutEnvironmentInstance,
    layoutEnvironmentPreset,
    layoutExternalRegistry,
    layoutIntegration,
    layoutNamespace,
    layoutNodeGroup,
    layoutPreset,
  };
};

export const [useLayoutData, LayoutDataProvider, LayoutDataConsumer] = createProvider<
  LayoutDataProviderType,
  PropsWithChildren<unknown>
>(initialData, useLayoutDataProviderHook, "LayoutData");
