import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    container: {
      borderRadius: "md",
      borderWidth: "1px",
    },
    button: {
      "&[aria-expanded='true']": {
        bg: "background.darker",
      },
    },
  },
});
